<template>
  <div id="modal-base">
    <div class="modal-header">
      <div class="d-flex items-center" style="gap: 5px">
        <feather-icon
          @click="close()"
          class="cursor-pointer"
          size="22"
          icon="ArrowLeftCircleIcon"
        />

        <div class="modal-title">Seleção de Produtos</div>
      </div>

      <i @click="close()" class="icon-close-circle-outline" />
    </div>

    <div class="search-box">
      <span class="search">
        <input-field @keyup="queueSearch()" placeholder="Buscar" v-model="search" />

        <i class="icon-search" />
      </span>

      <span v-if="hasRequest" class="d-flex justify-content-center my-1">
        <b-spinner />
      </span>
    </div>

    <infinite-scroll
      v-if="!hasRequest"
      @fetch="checkInfiniteScroll()"
      :hasRequest="hasScrollRequest"
      class="modal-content-holder nice-Vscroll"
    >
      <template v-slot:content>
        <div
          v-for="(product, index) in products"
          @click="simulateCheckClick(product)"
          :key="index"
          class="product-check"
        >
          <div v-if="!singularReturn" @click.stop>
            <b-form-checkbox
              @input="addToSelectedList(product)"
              :id="`product-check-${product.id}`"
              v-model="product.selected"
            />
          </div>

          <div class="product-info">
            <div class="product-name">{{ product.name }}</div>

            <div class="product-description">
              #{{ product.id }} - {{ $service.formatBrlAmountSymbol(product.price) }}
            </div>
          </div>
        </div>
      </template>
    </infinite-scroll>

    <div class="modal-buttons">
      <b-button @click="close()" variant="light">Cancelar</b-button>

      <b-button @click="emitSuccess()" variant="primary"> Confirmar </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BFormCheckbox, BSpinner } from "bootstrap-vue";
import InfiniteScroll from "@/views/components/base/InfiniteScroll.vue";
import InputField from "@/views/components/InputField.vue";

export default {
  components: {
    BButton,
    BFormCheckbox,
    BSpinner,
    InfiniteScroll,
    InputField,
  },

  props: {
    selectedProductsIds: {
      type: Array,
      default: () => [],
    },

    selectedProducts: {
      type: Array,
      default: () => [],
    },

    singularReturn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      queueingSearch: null,

      hasRequest: false,
      hasScrollRequest: false,

      search: "",
      limit: 100,
      offset: 0,
      totalCount: 0,

      products: [],
      localSelectedProductsId: [],
      localSelectedProducts: [],
    };
  },

  created() {
    this.setLocalSelectedProducts();
    this.fetchProducts();
  },

  methods: {
    close() {
      this.$emit("closed");
    },

    emitSuccess() {
      const selected = {
        ids: this.localSelectedProductsId,
        products: this.localSelectedProducts,
      };

      this.$emit("selected", selected);
      this.close();
    },

    simulateCheckClick(product) {
      if (this.singularReturn) {
        this.$emit("selected", product);
        this.close();

        return;
      }

      const divToClick = document.getElementById(`product-check-${product.id}`);

      if (divToClick) divToClick.click();
    },

    queueSearch() {
      window.clearTimeout(this.queueingSearch);

      this.queueingSearch = setTimeout(() => {
        this.fetchProducts();
      }, 850);
    },

    setLocalSelectedProducts() {
      this.localSelectedProductsId = this.selectedProductsIds;
      this.localSelectedProducts = this.selectedProducts;
    },

    addToSelectedList(product) {
      const index = this.localSelectedProductsId.findIndex((p) => p === product.id);

      if (index === -1) {
        this.localSelectedProductsId.push(product.id);
        this.localSelectedProducts.push(product);
      } else {
        this.localSelectedProductsId.splice(index, 1);
        this.localSelectedProducts.splice(index, 1);
      }
    },

    setSelectProducts() {
      this.products.forEach((element) => {
        const product = this.localSelectedProductsId.find((p) => p === element.id);

        element.selected = !!product;
      });
    },

    checkInfiniteScroll() {
      if (this.totalCount > this.offset) {
        this.offset += this.limit;

        this.fetchProducts(true);
      }
    },

    fetchProducts(fromScroll = false) {
      if (fromScroll) {
        this.hasScrollRequest = true;
      } else {
        this.hasRequest = true;

        this.offset = 0;
      }

      const params = [
        {
          query: "limit",
          param: this.limit,
        },

        {
          query: "offset",
          param: this.offset,
        },

        {
          query: "search",
          param: this.search,
        },
      ];

      this.$store
        .dispatch("opportunityStore/fetchProducts", this.$service.formatQueryParam(params))
        .then((response) => {
          this.totalCount = response.pagination.total_count;

          if (fromScroll) {
            this.products = [...this.products, ...response.data];
          } else {
            this.products = response.data;
          }

          this.setSelectProducts();
        })
        .finally(() => {
          this.hasRequest = false;
          this.hasScrollRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#modal-base {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  background-color: #ffffff;
  overflow: hidden;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 10px;
    border-bottom: 1px solid #ececec;

    .modal-title {
      font-size: 15px;
      font-weight: 600;
    }

    .icon-close-circle-outline {
      font-size: 22px;
      cursor: pointer;
    }
  }

  .search-box {
    padding: 1rem;

    .search {
      padding: 0px;
      margin: 0px;
      position: relative;
      border: 1px solid #e5e5e5;
      display: flex;

      .input-field-container {
        padding: 0px;
        margin: 0px;
        :deep(input) {
          border: none;
        }
      }

      .icon-search {
        position: absolute;
        right: 10px;
        line-height: 45px;
      }
    }
  }

  .modal-content-holder {
    display: flex;
    flex-direction: column;
    align-self: center;
    height: 100%;
    width: calc(100% - 2rem);
    margin-bottom: 10px;
    gap: 5px;
    border: 1px solid #ececec;
    overflow-x: hidden;

    .product-check {
      display: flex;
      align-items: center;
      word-break: break-word;
      width: 100%;
      padding: 10px;
      gap: 10px;
      border-bottom: 1px solid #ececec;
      cursor: pointer;

      .product-info {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;

        .product-name {
          font-size: 14px;
          color: #525c68;
        }

        .product-description {
          font-size: 14px;
          color: #9c9c9c;
        }
      }
    }

    .product-check:last-child {
      border-bottom: none;
    }

    .product-check:only-child {
      border-bottom: 1px solid #ececec;
    }
  }

  .modal-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    margin-top: auto;
    border-top: 1px solid #ececec;
    color: #000000;
    background: #ffffff;
  }
}
</style>
