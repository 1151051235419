<template>
  <div class="create-opportunity">
    <div class="create-opportunity-header">
      <div class="modal-title">Criar Oportunidade</div>

      <i @click="$emit('close')" class="icon-close-circle-outline" />
    </div>

    <validation-observer
      v-if="!hasSalesStageRequest"
      class="form-holder nice-Vscroll"
      ref="formValidation"
    >
      <qualification v-model="probability" />

      <opportunity-step :sales-stages="saleStages" creation-stages-only v-model="sales_stage_id" />

      <line-component color="#E5E5E5" />

      <legend>Informações</legend>

      <input-field
        id="opportunity_name"
        placeholder="Nome da Oportunidade"
        borderRadius="5px"
        type="text"
        trim
        v-model="name"
      />

      <validation-provider
        #default="{ errors }"
        name="Nome do Contato"
        rules="required"
        vid="contact.name"
      >
        <small class="v-err text-danger">{{ errors[0] }}</small>

        <querie-dropdown
          @clear="clearContact()"
          @optionPicked="selectContact($event)"
          :input-label="'Nome Completo'"
          :select-label="'name'"
          :second-select-label="'phone_number'"
          :async-function="getContacts"
          id="contact_name"
          v-model="contact.name"
        />
      </validation-provider>

      <validation-provider #default="{ errors }" name="celular" rules="required" vid="phone">
        <small class="v-err text-danger">{{ errors[0] }}</small>

        <small class="text-danger" v-if="needDDD"> O número precisa conter algum DDD </small>

        <vue-phone-number-input
          @update="setMobileNumberInformation"
          :border-radius="0"
          :color="colorOfNumberInput"
          :default-country-code="countryCode"
          :disabled="hasExternalContact"
          :translations="{
            countrySelectorLabel: 'Código do país',
            countrySelectorError: 'Escolha um país',
            phoneNumberLabel: 'Celular',
            example: 'Exemplo :',
          }"
          show-code-on-list
          class="phone-input"
          size="lg"
          valid-color="#046af3"
          v-model="phone_number"
        />
      </validation-provider>

      <input-field
        id="email"
        placeholder="email@dominio.com.br"
        borderRadius="5px"
        type="email"
        trim
        v-model="contact.email"
      />

      <line-component color="#E5E5E5" />

      <validation-provider
        #default="{ errors }"
        name="Responsável"
        rules="required"
        vid="currentUser"
      >
        <small class="v-err text-danger">{{ errors[0] }}</small>

        <span @click="showModalWithRoleCheck('bv-list_users')">
          <user-item label="Responsável" class="form-boxes" variant="input" v-model="currentUser" />
        </span>
      </validation-provider>

      <product-item
        @selected="selectProduct($event)"
        @clear="unsetProduct()"
        :products="product"
        singular-return
      />

      <b-form-group class="price-field">
        <money
          @blur.native="checkForMoneyError(price)"
          id="price"
          class="form-control price"
          placeholder="Venda"
          trim
          v-model="price"
        />

        <label for="price" class="floating"> {{ platform?.terms?.price_term }}: </label>
      </b-form-group>

      <line-component color="#E5E5E5" />

      <legend>Origem</legend>

      <validation-provider #default="{ errors }" name="Origem" rules="required" vid="source_type">
        <small class="v-err text-danger">{{ errors[0] }}</small>

        <v-select
          :options="source_types"
          :reduce="(option) => option.id"
          id="source_type"
          label="title"
          placeholder="Origem"
          v-model="source_type"
        />
      </validation-provider>

      <legend>Mídia</legend>

      <validation-provider #default="{ errors }" name="Mídia" rules="required" vid="media">
        <small class="v-err text-danger">{{ errors[0] }}</small>

        <v-select
          :options="medias"
          :reduce="(option) => option.id"
          id="media"
          label="name"
          placeholder="Mídia"
          v-model="media_id"
        />
      </validation-provider>

      <input-field
        id="campaign_url"
        placeholder="Site"
        borderRadius="5px"
        type="text"
        trim
        v-model="campaign_url"
      />

      <legend>Campanha</legend>

      <validation-provider #default="{ errors }" name="Campanha" vid="campaign">
        <small class="v-err text-danger">{{ errors[0] }}</small>

        <v-select
          :options="campaigns"
          :reduce="(option) => option.id"
          id="campaign"
          label="name"
          placeholder="Campanha"
          v-model="campaign_id"
        />
      </validation-provider>

      <v-select
        v-if="$service.isIm()"
        :options="opportunityTypes"
        :reduce="(option) => option.id"
        id="opportunity_type"
        label="text"
        placeholder="Tipo"
        v-model="opportunity_type_id"
      />

      <legend>Informações Complementares</legend>

      <date-time-picker
        id="start_timestamp"
        format="YYYY-MM-DD"
        formatted="DD/MM/YYYY"
        placeholder="Data de Nascimento"
        date-only
        no-button-now
        v-model="contact.birthdate"
      />

      <input-field
        :mask="['###.###.###-##']"
        id="CPF"
        placeholder="CPF"
        borderRadius="5px"
        type="text"
        trim
        v-model="contact.document"
      />

      <input-field
        id="RG"
        placeholder="RG"
        borderRadius="5px"
        type="text"
        trim
        v-model="contact.id_number"
      />

      <legend>Endereço</legend>

      <input-field
        id="cep"
        placeholder="CEP"
        borderRadius="5px"
        type="text"
        trim
        v-model="contact.postal_code"
      />

      <input-field
        id="address_line_1"
        placeholder="Endereço"
        borderRadius="5px"
        type="text"
        trim
        v-model="contact.address_line1"
      />

      <input-field
        id="address_line_2"
        placeholder="Complemento"
        borderRadius="5px"
        type="text"
        trim
        v-model="contact.address_line2"
      />

      <v-select
        label="title"
        placeholder="Estado"
        :options="states"
        :reduce="(option) => option.id"
        v-model="contact.state_code"
      />

      <input-field
        id="cidade"
        placeholder="Cidade"
        borderRadius="5px"
        type="text"
        trim
        v-model="contact.city_name"
      />

      <input-field
        id="bairro"
        placeholder="Bairro"
        borderRadius="5px"
        type="text"
        trim
        v-model="contact.neighborhood"
      />

      <legend>Redes Sociais</legend>

      <input-field
        id="instagram"
        placeholder="@instagram"
        borderRadius="5px"
        type="text"
        trim
        v-model="contact.instagram"
      />

      <input-field
        id="facebook"
        placeholder="@facebook"
        borderRadius="5px"
        type="text"
        trim
        v-model="contact.facebook"
      />

      <input-field
        id="linkedin"
        placeholder="@linkedIn"
        borderRadius="5px"
        type="text"
        trim
        v-model="contact.linkedin"
      />

      <div v-if="platform?.responsibleCheck" class="is-responsible">
        <legend>Dependentes</legend>

        <div class="responsibility-checkbox-holder">
          <input type="checkbox" class="custom-checkbox" v-model="is_responsible" />

          Não é aluno?
        </div>
      </div>

      <div v-if="is_responsible" class="is-responsible">
        <validation-provider #default="{ errors }" name="Nome" rules="required" vid="student_name">
          <small class="v-err text-danger">{{ errors[0] }}</small>

          <input-field
            id="name"
            placeholder="Nome Completo"
            type="text"
            borderRadius="5px"
            trim
            v-model="student_name"
          />
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="CPF"
          rules="required"
          vid="student_document"
        >
          <small class="v-err text-danger">{{ errors[0] }}</small>

          <input-field
            :mask="['###.###.###-##']"
            id="CPF"
            placeholder="CPF"
            type="text"
            borderRadius="5px"
            trim
            v-model="student_document"
          />
        </validation-provider>
      </div>

      <b-form-textarea
        @blur="checkDescriptionLimit($event)"
        :style="description?.length > 10000 ? 'border-color: #EA5455' : ''"
        placeholder="Observação"
        max-rows="2"
        rows="2"
        v-model="description"
      />
    </validation-observer>

    <div v-else class="spinner-holder">
      <b-spinner />
    </div>

    <div class="bottom-menu">
      <b-button @click="$emit('close')" variant="light">Cancelar</b-button>

      <b-button @click="createOpportunity()" variant="primary">
        <b-spinner v-if="hasSaveRequest" small />

        <div v-else>Criar</div>
      </b-button>
    </div>

    <b-modal class="modal-dialog" id="bv-list_users" hide-footer hide-header>
      <user-list @closed="$bvModal.hide('bv-list_users')" @selected="selectUser($event)" />
    </b-modal>
  </div>
</template>

<script>
import { BButton, BFormGroup, BFormTextarea, BSpinner } from "bootstrap-vue";
import { Money } from "v-money";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import DateTimePicker from "@/views/forms/components/DateTimePicker.vue";
import InputField from "@/views/components/InputField.vue";
import Line from "@/views/components/Line.vue";
import OpportunityStep from "@/views/components/OpportunityStep.vue";
import ProductItem from "@/views/components/ProductItem.vue";
import Qualification from "@/views/components/Qualification.vue";
import QuerieDropdown from "@/views/components/base/QuerieDropdown.vue";
import UserItem from "@/views/components/UserItem.vue";
import UserList from "@/views/components/UserList.vue";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    Money,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput,

    DateTimePicker,
    InputField,
    OpportunityStep,
    ProductItem,
    Qualification,
    QuerieDropdown,
    UserItem,
    UserList,

    "line-component": Line,
  },

  props: {
    presetContact: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      saleStages: [],

      currentUser: null,

      hasSaveRequest: false,
      hasSalesStageRequest: false,

      hasExternalContact: false,

      //Opportunity Data
      name: null,
      sales_stage_id: null,
      probability: 40,
      product: null,
      price: 0,
      source_type: null,
      media_id: null,
      campaign_id: null,
      campaign_url: null,
      description: null,
      is_responsible: false,
      student_name: null,
      student_document: null,
      opportunity_type_id: null,

      //Contact Data
      contact: {},
      phone_number: null,

      //Phone Number Data
      countryCode: "BR",
      editedPhoneNumber: null,
      colorOfNumberInput: "#d8d6de",
      needDDD: false,
    };
  },

  computed: {
    role() {
      return this.$store.getters["app/getUserRole"];
    },

    user() {
      return this.$store.getters["app/getUserData"];
    },

    medias() {
      return this.$store.getters["opportunityStore/getMedias"];
    },

    campaigns() {
      return this.$store.getters["opportunityStore/getCampaigns"];
    },

    states() {
      return this.$store.getters["opportunityStore/getSourceStates"];
    },

    opportunityTypes() {
      return this.$store.getters["opportunityStore/getOpportunityTypes"];
    },

    platform() {
      return this.$store.getters["whiteLabel/getPlatform"];
    },

    source_types() {
      const deepClone = require("lodash");

      let options = deepClone.cloneDeep(this.$store.getters["opportunityStore/getSourceTypes"]);

      let sources = Object.values(options);

      return sources.filter(
        (option) =>
          option.id != "URA" &&
          option.id != "Bella" &&
          option.id != "FormularioSite" &&
          option.id != "Importacao"
      );
    },
  },

  created() {
    this.setPresetValues();

    this.fetchSalesStages();
    this.fetchCampaigns();
    this.fetchMedias();
  },

  methods: {
    showModalWithRoleCheck(modal) {
      if (this.role !== "salesRep") {
        this.$bvModal.show(modal);
      }
    },

    selectUser(user) {
      this.currentUser = user;

      this.$bvModal.hide("bv-list_users");
    },

    selectContact(event) {
      if (event?.phone_number) {
        this.contact = { ...event };
        this.phone_number = event.phone_number;
        this.hasExternalContact = true;
      }
    },

    selectProduct(product) {
      this.product = product;

      if (product.price) this.price = product.price;
    },

    setPresetValues() {
      if (this.platform.preset_values) {
        this.source_type = this.platform.preset_values.source_type;

        this.media_id = this.platform.preset_values.media_id;
      }

      this.opportunity_type_id = this.platform.default_opportunity_type;

      this.currentUser = this.user;

      if (this.presetContact) this.selectContact(this.presetContact);
    },

    unsetProduct() {
      this.product = null;
    },

    clearContact() {
      this.$service.resetObjectFieldsToUndefined(this.contact);
      this.phone_number = "";

      this.hasExternalContact = false;
    },

    setMobileNumberInformation(update) {
      this.countryCode = update.countryCode;
      this.editedPhoneNumber = update.formatNational;

      if (
        this.editedPhoneNumber &&
        this.editedPhoneNumber.length < 10 &&
        this.countryCode === "BR"
      ) {
        this.colorOfNumberInput = "#ea5556";
        this.needDDD = true;
      } else {
        this.needDDD = false;
      }
    },

    checkForMoneyError(value) {
      if (value < 0) {
        this.price = 0;
      } else if (value > 9999999.99) {
        this.price = 9999999.99;
      }
    },

    checkDescriptionLimit() {
      if (this.description.length > 10000) {
        this.$bvToast.toast(
          "O limite de caracteres do campo de descrição é de 10.000 caracteres.",
          {
            title: `Limite da Descrição alcançado!`,
            autoHideDelay: 2500,
            variant: "warning",
            toaster: "b-toaster-top-left",
            solid: true,
          }
        );

        this.description = this.description.substring(0, 10000);
      }
    },

    async getContacts(search) {
      const defaultParams = [
        { query: "search", param: search },
        { query: "limit", param: 25 },
      ];

      try {
        const response = await this.$store.dispatch(
          "contactStore/list",
          this.$service.formatQueryParam(defaultParams)
        );

        return response.data;
      } catch (error) {
        return null;
      }
    },

    getClearOpportunity() {
      if (this.contact) {
        delete this.contact.id;
        delete this.contact.workspace_id;
        delete this.contact.workspace;
        delete this.contact.opportunities;
        delete this.contact.created_at;
        delete this.contact.updated_at;
      }

      const opportunity = {
        name: this.name,
        state_type: "Open",
        opportunity_type_id: this.opportunity_type_id,
        source_type: this.source_type,
        media_id: this.media_id,
        is_responsible: this.is_responsible,
        student_name: this.student_name,
        student_document: this.student_document,
        description: this.description,
        campaign_id: this.campaign_id,
        campaign_url: this.campaign_url,
        probability: this.probability,
        user_id: this.currentUser.id,
        product_id: this.product?.id,
        price: this.price,
        contact_id: this.contact.id,
        sales_stage_id: this.sales_stage_id,

        contact: this.contact,
      };

      opportunity.contact.phone_number = this.phone_number;
      opportunity.contact.country_code = this.countryCode;

      if (this.hasExternalContact) opportunity.contact_id = this.contact.id;

      if (!this.name) opportunity.name = this.contact.name;

      return opportunity;
    },

    fetchCampaigns() {
      if (this.campaigns.length) return;

      this.$store.dispatch("opportunityStore/fetchCampaigns");
    },

    fetchMedias() {
      if (this.medias.length) return;

      this.$store.dispatch("opportunityStore/fetchMedias");
    },

    fetchSalesStages() {
      this.hasSalesStageRequest = true;

      this.$store
        .dispatch("admin/fetchSalesStages")
        .then((response) => {
          this.saleStages = response.data;

          const openingStage = this.saleStages?.find((stage) => stage.type == "First");

          if (openingStage) {
            this.sales_stage_id = openingStage.id;
          }
        })
        .finally(() => {
          this.hasSalesStageRequest = false;
        });
    },

    createOpportunity() {
      if (this.hasSaveRequest) return;

      const formRefs = this.$refs.formValidation;

      formRefs.validate().then((success) => {
        if (success) {
          this.hasSaveRequest = true;

          const opportunity = this.getClearOpportunity();

          this.$store
            .dispatch("opportunityStore/save", opportunity)
            .then(() => {
              this.$emit("saved");
            })
            .finally(() => {
              this.hasSaveRequest = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-opportunity {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100dvh;
  width: 100%;
  overflow: hidden;

  .create-opportunity-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 10px;
    border-bottom: 1px solid #ececec;
    color: #31333f;

    .modal-title {
      font-size: 15px;
      font-weight: 600;
    }

    .icon-close-circle-outline {
      font-size: 22px;
      cursor: pointer;
    }
  }

  .form-holder {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1.5rem;
    gap: 15px;
    overflow-x: hidden;
    overflow-y: auto;

    legend {
      font-size: 1em;
      padding-top: 0.5em;
      margin-bottom: -5px;
      color: #000000;
    }

    .input-field-container {
      margin: 0 !important;
    }

    .form-boxes {
      padding: 5px;
      border-radius: 5px;
      margin: 0;
      cursor: pointer;
    }

    .price-field {
      margin: 0 !important;

      .floating {
        float: left;
        font-size: 15px;
        margin-top: -30px;
        margin-left: 16px;
        color: #535e68;
      }

      .price {
        text-align: right;
        background: transparent;
        color: black;
        font-weight: 600;
      }
    }

    .is-responsible {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .responsibility-checkbox-holder {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        .custom-checkbox {
          height: 18px;
          width: 18px;
          border-radius: 2px;
        }
      }
    }
  }

  .form-holder > * {
    flex-shrink: 0;
  }

  .spinner-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .bottom-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    margin-top: auto;
    border-top: 1px solid #ececec;
    color: #000000;
    background: #ffffff;
  }
}
</style>
