<template>
  <div class="verify-cancel">
    <div class="verify-cancel-header">
      <i @click="$emit('close')" class="icon-close" style="cursor: pointer" />
    </div>

    <div class="verify-cancel">
      <h2>Salvar atualizações</h2>

      <h4>Você não salvou suas alterações.</h4>

      <h4>Escolha alguma opção!</h4>

      <div class="verify-buttons">
        <button @click="$emit('close')" class="verify-button-close">SAIR SEM SALVAR</button>

        <button @click="$emit('save')" class="verify-button-save">
          <span>SALVAR</span>
        </button>
      </div>

      <img
        src="@/assets/images/write-notebook-illustration.svg"
        alt="watch-verify"
        class="w-100 mt-4 mx-auto"
      />
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation,
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.verify-cancel {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .verify-cancel-header {
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 50px;

    i {
      font-size: 16px;
      color: black;
    }
  }

  .verify-cancel {
    max-width: 500px;
    height: 100vh;
    padding: 25px;
    text-align: center;
    overflow-y: hidden;

    h2 {
      color: black;
      margin-bottom: 20px;
      font-weight: bold;
    }

    .verify-buttons {
      margin-top: 35px;
      width: 100%;
      height: 50px;
      align-items: center;
      display: flex;
      justify-content: space-around;

      .verify-button-close {
        width: 35vw;
        margin: 0 5px;
        background: transparent;
        border: none;
        color: black;
        font-weight: bold;
      }

      .verify-button-save {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35vw;
        height: 45px;
        margin: 0 5px;
        font-weight: bold;
        border: none;
        background: #046af3;
        border-radius: 10px;
        color: #fff;
      }
    }
  }
}
</style>
