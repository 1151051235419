<template>
  <div class="qualification">
    <div class="qualification-text">
      <feather-icon
        @click="$bvModal.show('bv-help_modal-qualification')"
        icon="AlertCircleIcon"
        class="alert-icon"
        size="24"
      />

      <div>Qualificação</div>
    </div>

    <RateBar @update-rating="$emit('input', $event)" is-edit :rate="value" :size="2" />

    <b-modal class="modal-dialog" id="bv-help_modal-qualification" hide-footer hide-header>
      <div class="d-block text-center">
        <help type="rating" @closed="$bvModal.hide('bv-help_modal-qualification')" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Help from "@/views/components/Help.vue";
import RateBar from "@/views/components/RateBar.vue";

export default {
  components: {
    Help,
    RateBar,
  },

  props: {
    value: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    updateRating(value) {
      this.opportunity.probability = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.qualification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  gap: 15px;

  .qualification-text {
    display: flex;
    align-items: center;
    font-size: 18px;
    gap: 5px;

    .alert-icon {
      color: #046af3;
      fill: #d4e2f3;
    }
  }
}
</style>
