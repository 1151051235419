<template>
  <div @click="$bvModal.show('bv-list_products-product-item')" class="product-item-main">
    <div v-if="!products || (Array.isArray(products) && !products.length)" class="product-info">
      <div class="overflow-text">Selecione {{ platform_terms.product_term }}</div>

      <i class="icon-search" />
    </div>

    <div v-else-if="products && !products?.length" class="product-info">
      <div class="single-product-info">
        <div class="overflow-text">{{ products.name }}</div>

        <div class="overflow-text small-text">
          Cód #{{ products.id }} - {{ $service.formatBrlAmountSymbol(products.price) }}
        </div>
      </div>

      <i v-if="clearable" @click.stop="clearProducts()" class="icon-close" />
    </div>

    <div v-else class="product-info">
      <div class="overflow-text">{{ getProductNames() }}</div>

      <i v-if="clearable" @click.stop="clearProducts()" class="icon-close" />
    </div>

    <b-modal class="modal-dialog" id="bv-list_products-product-item" hide-footer hide-header>
      <div class="d-block text-center">
        <list-products
          @closed="$bvModal.hide('bv-list_products-product-item')"
          @selected="selectProduct($event)"
          :selected-products-ids="localProductsIds"
          :selected-products="localProducts"
          :singular-return="singularReturn"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import ListProducts from "@/views/components/ListProducts.vue";

export default {
  components: {
    ListProducts,
  },

  props: {
    products: {
      default: null,
    },

    singularReturn: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      localProducts: [],
      localProductsIds: [],
    };
  },

  computed: {
    platform_terms() {
      const platformData = this.$store.getters["whiteLabel/getPlatform"];

      return platformData.terms;
    },
  },

  created() {
    this.getLocalValues();
  },

  watch: {
    products: {
      handler() {
        this.getLocalValues();
      },
      deep: true,
    },
  },

  methods: {
    getLocalValues() {
      if (Array.isArray(this.products)) {
        this.localProducts = this.products;
        this.localProductsIds = this.products.map((product) => product.id);

        return;
      }

      this.localProducts = [this.products || null];
      this.localProductsIds = [this.products?.id || null];
    },

    getProductNames() {
      if (this.products?.length) {
        let names = "";

        this.products.forEach((product, index) => {
          names += product.name;

          if (index < this.products.length - 1) {
            names += ", ";
          } else {
            names += ".";
          }
        });

        return names;
      }

      return this.products?.name || "";
    },

    clearProducts() {
      this.localProducts = [];
      this.localProductsIds = [];

      this.$emit("clear");
    },

    selectProduct($event) {
      if (this.singularReturn) {
        this.localProducts = [$event];
        this.localProductsIds = [$event.id];
      } else {
        this.localProducts = $event.products;
        this.localProductsIds = $event.ids;
      }

      this.$emit("selected", $event);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-item-main {
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  padding: 13px;
  border-radius: 5px;
  border: solid 1px #d2d2d2;
  color: #252525;
  overflow: hidden;
  cursor: pointer;

  .product-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .single-product-info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 25px);
  }

  .small-text {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #999999;
  }

  .overflow-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    max-width: 100%;
  }
}
</style>
