import { render, staticRenderFns } from "./InfiniteScroll.vue?vue&type=template&id=89153306&scoped=true"
import script from "./InfiniteScroll.vue?vue&type=script&lang=js"
export * from "./InfiniteScroll.vue?vue&type=script&lang=js"
import style0 from "./InfiniteScroll.vue?vue&type=style&index=0&id=89153306&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89153306",
  null
  
)

export default component.exports