<template>
  <div class="win-lost-buttons">
    <div @click="$emit('set-lost')" :class="{ lost: isLost }" class="lost-button">
      <feather-icon icon="ThumbsDownIcon" size="17" />

      <div>{{ platform?.terms?.lost_term || "Perdido" }}</div>
    </div>

    <div class="divisor" />

    <div @click="$emit('set-won')" :class="{ won: isWon }" class="won-button">
      <feather-icon icon="ThumbsUpIcon" size="17" />

      <div>{{ platform?.terms?.won_term || "Ganho" }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    opportunity: {
      type: Object,
      required: true,
    },
  },

  computed: {
    platform() {
      return this.$store.getters["whiteLabel/getPlatform"];
    },

    isWon() {
      return this.opportunity.state_type === "Won";
    },

    isLost() {
      return this.opportunity.state_type === "Lost";
    },
  },
};
</script>

<style lang="scss" scoped>
.win-lost-buttons {
  display: flex;
  align-items: stretch;
  width: 100%;

  .won-button,
  .lost-button {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    padding: 12px 14px;
    border: 1px solid #d8d6de;
    cursor: pointer;
  }

  .won-button {
    border-radius: 0 5px 5px 0;
    border-left: none;
    color: #046af3;

    &.selected {
      background: #046af3;
      color: #ffffff;
    }
  }

  .lost-button {
    border-radius: 5px 0 0 5px;
    border-right: none;
    color: #ee1111;

    &.selected {
      background: #ee1111;
      color: #ffffff;
    }
  }

  .divisor {
    width: 1px;
    background: #d8d6de;
  }

  .won,
  .lost {
    color: #ffffff !important;
  }

  .won {
    border-color: #046af3;
    background: #046af3;
  }

  .lost {
    border-color: #ee1111;
    background: #ee1111;
  }
}
</style>
