import { instance } from "@/config/index.js";

const initialState = () => ({
  omniChatId: 0,
  omniOpportunityId: 0,

  updateVar: false,

  isHovering: false,

  opportunityReselectData: null,

  quickMessages: [
    {
      id: "/saudacao",
      message: "Olá! Bem-vindo à nossa loja virtual. Como posso ajudar você hoje?",
    },
    {
      id: "/novos_clientes",
      message:
        "Olá, novo cliente! É um prazer tê-lo aqui. Se tiver alguma dúvida sobre nossos produtos ou serviços, fique à vontade para perguntar.",
    },
    {
      id: "/retorno",
      message:
        "Olá novamente! Estamos felizes em vê-lo de volta. Tem algo específico em mente hoje?",
    },
    {
      id: "/saudacao_oferta",
      message:
        "Olá! Temos uma oferta especial para você hoje. Como posso ajudá-lo a aproveitar ao máximo?",
    },
    {
      id: "/saudacao_personalizada",
      message:
        "Olá [Nome do Cliente]! Que bom vê-lo por aqui. Como posso ajudá-lo a encontrar o que procura hoje?",
    },
    {
      id: "/interesse",
      message:
        "Olá! Percebemos que você estava interessado em [Produto/Serviço]. Estamos à disposição para responder a quaisquer perguntas ou fornecer mais informações.",
    },
    {
      id: "/promocao",
      message: "Olá! Temos uma promoção incrível em andamento. Posso lhe contar mais sobre isso?",
    },
    {
      id: "/atendimento",
      message:
        "Olá! A equipe de atendimento ao cliente está aqui para ajudar. Como podemos tornar sua experiência melhor hoje?",
    },
    {
      id: "/saudacao_personalizacao",
      message:
        "Olá! Sabemos que cada cliente é único. Como podemos personalizar sua experiência hoje?",
    },
    {
      id: "/feedback",
      message:
        " Olá! Estamos constantemente melhorando nossos produtos e serviços. Adoraríamos ouvir seus comentários e sugestões.",
    },
    {
      id: "/informacao_produto",
      message:
        " Posso fornecer informações detalhadas sobre o produto [Nome do Produto] que você está interessado. Qualquer dúvida em particular?",
    },
    {
      id: "/desconto_especial",
      message:
        " Ótima notícia! Temos um desconto especial de [Porcentagem de Desconto] disponível para o [Nome do Produto]. Você gostaria de aproveitar essa oferta?",
    },
    {
      id: "/preferencia",
      message:
        "Para personalizar sua experiência, gostaríamos de saber suas preferências. Você prefere produtos [Opção A] ou [Opção B]?",
    },
    {
      id: "/disponibilidade",
      message:
        "Você gostaria de verificar a disponibilidade do produto [Nome do Produto] na sua região?",
    },
    {
      id: "/sugestao",
      message:
        "Para tornar sua compra ainda melhor, sugerimos adicionar [Nome do Complemento] ao seu carrinho. Ele complementa perfeitamente o [Nome do Produto].",
    },
    {
      id: "/acompanhamento_pedido",
      message:
        "Posso ajudar a verificar o status do seu pedido? Basta me fornecer o número do pedido e verifico imediatamente.",
    },
    {
      id: "/retorno_informacao",
      message:
        "Obrigado por entrar em contato conosco. Vou verificar essa informação para você e retornar em breve.",
    },
    {
      id: "/garantia",
      message:
        "Todos os nossos produtos vêm com garantia. Você gostaria de saber mais sobre os termos da garantia?",
    },
    {
      id: "/feedback_cliente",
      message:
        "A opinião dos nossos clientes é importante para nós. Gostaríamos de ouvir seus comentários sobre sua experiência até agora.",
    },
    {
      id: "/agradecimento_final",
      message:
        "Obrigado por escolher [Nome da Sua Empresa]! Se precisar de mais alguma ajuda, não hesite em entrar em contato.",
    },
  ],
});

const getters = {
  getUpdateTick(state) {
    return state.updateVar;
  },

  getIsHovering(state) {
    return state.isHovering;
  },

  getQuickMessages(state) {
    return state.quickMessages;
  },

  getOpportunityReselectData(state) {
    return state.opportunityReselectData;
  },
};

const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },

  clearIds({ commit }) {
    commit("RESET_IDS");
  },

  changeHoveringState({ state }, boolean) {
    state.isHovering = boolean;
  },

  sendMessage({}, message) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/dialogs/${message.dialog_id}/messages`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, message.message_object)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  closeChat({}, chat) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`/workspaces/${workspaceId}/dialogs/${chat}/close`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  reopenChat({}, chatData) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`/workspaces/${workspaceId}/dialogs/${chatData.dialog_id}/open`, chatData)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  openChat({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/dialogs`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  listBots({}, params = "") {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/bots${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  listChats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dialogs${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getChat({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dialogs/${data.chat_id}/messages${data.params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  listTemplatesByBot({}, bot_id) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/bots/${bot_id}/meta-templates`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getOneTemplateByBot({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/bots/${data.bot_id}/meta-templates/${data.template_name}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  putTemplateByBot({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .put(`/bots/${data.bot_id}/meta-templates`, data.template)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getOneDialog({}, chatId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dialogs/${chatId}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  listMassMessages({}) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/batch-messages`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  listOneMassMessage({}, batchId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/batch-messages/${batchId}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  createMassMessaging({}, formData) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`/workspaces/${workspaceId}/batch-messages`, formData)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  resumeBatchMessaging({}, batchId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`/workspaces/${workspaceId}/batch-messages/${batchId}/resume`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  pauseBatchMessaging({}, batchId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`/workspaces/${workspaceId}/batch-messages/${batchId}/pause`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  stopBatchMessaging({}, batchId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`/workspaces/${workspaceId}/batch-messages/${batchId}/cancel`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  deleteMessage({}, deletionData) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .delete(
          `/workspaces/${workspaceId}/dialogs/${deletionData.dialog_id}/messages/${deletionData.message_id}`
        )
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  CHANGE_OPPORTUNITY_ID(state, id) {
    state.omniOpportunityId = id;
  },

  CHANGE_CHAT_ID(state, id) {
    state.omniChatId = id;
  },

  CHANGE_OPPORTUNITY_RESELECT_DATA(state, data) {
    state.opportunityReselectData = data;
  },

  UPDATE(state) {
    state.updateVar = !state.updateVar;
  },

  RESET_IDS(state) {
    state.omniOpportunityId = 0;
    state.omniChatId = 0;
  },

  RESET_STATE(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      if (key !== "omniOpportunityId" && key !== "omniChatId") {
        state[key] = initial[key];
      }
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};
