<template>
  <div class="floating-messages">
    <div class="semi-modal-header">
      <feather-icon
        v-if="hasTemplateSelection"
        @click="hasTemplateSelection = false"
        icon="ArrowLeftCircleIcon"
        class="icon-style"
        size="20"
      />

      <i @click="closeFloatingNewRooms" class="icon-close-circle-outline" />
    </div>

    <div class="gray-detail" />

    <div v-if="!hasBotsRequest && imbotBots.length" class="box-title">
      {{ hasTemplateSelection ? "Templates" : "Iniciar conversa" }}
    </div>

    <div
      v-if="!hasBotsRequest && imbotBots.length && !hasTemplateSelection && !showTemplateOrMessage"
      class="subtitle-holder"
    >
      <div class="subtitle">Selecione um Bot integrado ao QRcode para iniciar a conversa.</div>

      <div class="keep-bot-holder">
        <input
          v-model="keepBotOnDialog"
          type="checkbox"
          id="keep-bot-on-dialog"
          name="keep-bot-on-dialog"
        />

        <label for="keep-bot-on-dialog">Manter Bot na conversa</label>
      </div>
    </div>

    <div v-if="!hasBotsRequest && !imbotBots.length" class="box-title">
      Nenhum Bot encontrado :(
    </div>

    <div v-if="!hasBotsRequest && !imbotBots.length" class="subtitle">
      Você não possui nenhum Bot integrado ao QRCode.
    </div>

    <div v-if="showTemplateOrMessage" class="subtitle">
      Selecione se você deseja enviar uma mensagem ou um template.
    </div>

    <div
      v-if="hasBotsRequest || hasTemplateRequest || hasTemplateSelectRequest"
      class="box-with-no-bots"
    >
      <lottie-animation
        class="lottie-animation"
        ref="anim"
        :animationData="require('@/assets/images/lottie/uploading-task-data.json')"
        :loop="true"
        :autoPlay="true"
        :speed="1"
      />
    </div>

    <div
      v-else-if="imbotBots.length && !hasTemplateSelection && !showTemplateOrMessage"
      class="bots-box"
    >
      <div v-for="(bot, index) in imbotBots" @click="selectBot(bot)" :key="index" class="bots">
        <div class="bot-icon">
          <i class="icon-imbot" />
        </div>

        <div class="bot-name">{{ bot.name }}</div>

        <div v-if="bot.is_whatsapp_business_connected" class="api-badge">API</div>
      </div>
    </div>

    <div v-else-if="showTemplateOrMessage && !hasTemplateSelection" class="bots-box">
      <div @click="selectBot(currentBot, true)" class="bots">
        <div class="bot-icon">
          <i class="icon-imbot" />
        </div>

        <div class="bot-name">Enviar Mensagem</div>
      </div>

      <div @click="fetchTemplates(currentBot)" class="bots">
        <div class="bot-icon">
          <i class="icon-imbot" />
        </div>

        <div class="bot-name">Enviar Template</div>
      </div>
    </div>

    <div v-else-if="hasTemplateSelection && templates.length" class="bots-box">
      <div
        v-for="(template, index) in templates"
        @click="selectTemplate(template)"
        :key="index"
        class="templates"
      >
        <div class="template-icon">
          <i class="icon-chat-ballon" />
        </div>

        <div class="template-description">
          <div class="template-name">{{ template.name }}</div>

          <div class="template-text">{{ template.body.text }}</div>
        </div>
      </div>
    </div>

    <div v-else class="box-with-no-bots">
      <img src="@/assets/images/icons/No-bots.svg" alt="no-bots" />
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation,
  },

  props: {
    opportunity: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      hasBotsRequest: true,
      hasTemplateRequest: false,
      hasTemplateSelectRequest: false,

      showTemplateOrMessage: false,
      hasTemplateSelection: false,

      keepBotOnDialog: false,

      currentBot: {},

      imbotBots: [],
      templates: [],
    };
  },

  mounted() {
    this.getImbotBots();
  },

  methods: {
    emit(event, params) {
      this.$emit(event, params);
    },

    closeFloatingNewRooms() {
      this.emit("close");
    },

    selectBot(bot, bypassTemplate = false) {
      bot.keep_bot_on_dialog = this.keepBotOnDialog;

      this.currentBot = bot;

      if (
        bot.is_whatsapp_business_connected &&
        bot.is_whatsapp_qr_code_connected &&
        !bypassTemplate
      ) {
        this.showTemplateOrMessage = true;

        return;
      }

      if (bot.is_whatsapp_business_connected && !bot.is_whatsapp_qr_code_connected) {
        this.fetchTemplates(bot);

        return;
      }

      this.emit("selectBot", bot);
    },

    getImbotBots() {
      this.hasBotsRequest = true;

      this.$store
        .dispatch("omnichannel/listBots")
        .then((response) => {
          this.imbotBots = response.data;

          if (this.imbotBots.length === 1) {
            this.selectBot(this.imbotBots[0]);
          }
        })
        .finally(() => {
          this.hasBotsRequest = false;
        });
    },

    fetchTemplates(bot) {
      if (this.hasTemplateRequest) return;

      this.showTemplateOrMessage = false;

      this.hasTemplateRequest = true;
      this.hasTemplateSelection = true;

      this.$store
        .dispatch("omnichannel/listTemplatesByBot", bot.id)
        .then((response) => {
          this.templates = response.data;
        })
        .finally(() => {
          this.hasTemplateRequest = false;
        });
    },

    selectTemplate(template) {
      this.hasTemplateSelectRequest = true;

      const params = {
        template_name: template.name,
        bot_id: this.currentBot.id,
      };

      this.$store
        .dispatch("omnichannel/getOneTemplateByBot", params)
        .then((response) => {
          const data = {
            ...response.data,
            bot: this.currentBot,
          };

          this.emit("selectTemplate", data);
        })
        .finally(() => {
          this.hasTemplateSelectRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1000;
  bottom: calc(100% + 1px);
  padding-top: 1rem;
  border-radius: 20px 20px 0px 0px;
  border: 1px solid #e5e5e5;
  box-shadow: -5px -5px 16px 0px #0000001a;
  background: #ffffff;

  .semi-modal-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding-right: 1rem;
    color: #434343;
  }

  .icon-close-circle-outline,
  .icon-style {
    font-size: 18px;
    color: #14223b;
    cursor: pointer;
  }

  .icon-close-circle-outline:hover,
  .icon-style:hover {
    color: #ff0000 !important;
  }

  .gray-detail {
    height: 5px;
    width: 100%;
    max-width: 135px;
    margin-bottom: 20px;
    border-radius: 20px;
    opacity: 0.5;
    background: #c4ccd8;
  }

  .box-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    margin-bottom: 15px;
    color: #000000;
  }

  .subtitle-holder {
    display: flex;
    flex-direction: column;
    width: 100%;

    .keep-bot-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      padding-bottom: 10px;
      gap: 5px;

      label {
        font-size: 12px;
        margin: 0;
        color: #4c5862;
        user-select: none;
      }
    }
  }

  .subtitle {
    text-align: center;
    margin-bottom: 10px;
    color: #4c5862;
  }

  .box-with-no-bots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    padding: 1rem;
    overflow: hidden;

    .lottie-animation {
      width: 100%;
      height: 100%;
    }

    img {
      width: 165px;
      height: auto;
    }
  }

  .bots-box {
    display: flex;
    flex-direction: column;
    max-height: 350px;
    width: 100%;
    overflow-y: scroll;

    .templates {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      gap: 5px;
      border-bottom: solid 1px #e5e5e5;
      cursor: pointer;

      .template-icon {
        i {
          font-size: 20px;
          color: #046af3;
        }
      }

      .template-description {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
        gap: 5px;

        .template-name {
          font-size: 16px;
          font-weight: 500;
          line-height: 12px;
          letter-spacing: 0.01em;
          color: #2f343d;
        }

        .template-text {
          font-size: 12px;
          font-weight: 300;
          line-height: 15px;
          text-align: left;
          color: #2f343da6;
        }
      }
    }

    .templates:last-child {
      border-bottom: none;
    }

    .templates:hover {
      background: #e5e5e5;

      i {
        color: #000000;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      border: 1px solid transparent;
      background-clip: content-box;
      box-shadow: inset 0 0 0 10px #c8c8c8;
    }

    .bots {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      gap: 5px;
      border-bottom: solid 1px #e5e5e5;
      cursor: pointer;

      .bot-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        background: #02cdad;

        i {
          font-size: 20px;
          color: #ffffff;
        }
      }

      .bot-name {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #4c5862;
      }

      .api-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        height: 30px;
        width: 65px;
        border-radius: 50px;
        background: #02cdad;
        color: #ffffff;
      }
    }

    .bots:last-child {
      border-bottom: none;
    }

    .bots:hover {
      background: #e5e5e5;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      border: 1px solid transparent;
      background-clip: content-box;
      box-shadow: inset 0 0 0 10px #c8c8c8;
    }
  }
}

@media (max-width: 800px) {
  .floating-messages {
    width: 100vw;
  }
}
</style>
