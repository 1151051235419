<template>
  <div class="sales-stage-selector">
    <div class="sales-stage-selector-header">
      <div class="modal-title">Etapa do Funil</div>

      <i @click="$emit('close')" class="icon-close-circle-outline" />
    </div>

    <div class="content-holder nice-Vscroll">
      <div
        v-for="(stage, index) in salesStages"
        @click="selectStage(stage)"
        :style="{ borderColor: '#' + stage.color }"
        :key="index"
        class="step-holder"
      >
        {{ stage.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    BSpinner,
  },

  props: {
    salesStages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  methods: {
    selectStage(stage) {
      this.$emit("select", stage);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-stage-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .sales-stage-selector-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 10px;
    border-bottom: 1px solid #ececec;
    color: #31333f;

    .icon-close-circle-outline {
      font-size: 22px;
      cursor: pointer;
    }

    .modal-title {
      font-size: 15px;
      font-weight: 600;
    }
  }

  .content-holder {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 10px 1rem;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    color: #6e6b7b;

    .step-holder {
      font-size: 14px;
      width: 100%;
      padding: 1rem;
      border-radius: 5px;
      border: 1px solid #000000;
      border-left: 3px solid #000000;
      background: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
