<template>
  <div>
    <div v-if="hasOpportunityRequest" class="loading-task">
      <lottie-animation
        ref="anim"
        :animationData="require('@/assets/images/lottie/pipeline-loading-lottie.json')"
        :loop="true"
        :autoPlay="true"
        :speed="1"
      />
    </div>

    <div v-else id="taskForm">
      <div v-if="isUpdate" class="fixed-infos">
        <task-top-info @close="close()" @finished="$emit('saved')" :task="task" />
      </div>

      <card-top-info-component
        v-if="localOpportunity && isUpdate"
        @finished="$emit('saved')"
        :opportunity="localOpportunity"
        :fromTaskForm="true"
        :task="task"
        :newestChat="newestChat"
        class="p-1"
      />

      <h3 v-if="alternativeTitle || !isUpdate" class="mt-1 mb-2 mx-auto">
        {{ alternativeTitle ? alternativeTitle : title }}
      </h3>

      <div class="form-body" title="Registro" v-if="isRegistry">
        <validation-observer ref="taskRegistryValidation">
          <b-form @submit.prevent>
            <legend v-if="isUpdate">Atualize seu registro de tarefa.</legend>

            <validation-provider
              #default="{ errors }"
              name="Oportunidade"
              vid="localOpportunity"
              rules="required"
            >
              <small class="text-danger">{{ errors[0] }}</small>

              <opportunity-item
                class="costumer-avatar cursor-pointer"
                label="Responsável"
                single-row
                variant="input"
                showClear
                selectionEnabled
                input
                id="localOpportunity"
                selectionLabel="Selecionar Oportunidade..."
                :opportunity="localOpportunity"
                @clear="clearOpportunity"
                @click="openModal('bv-opportunity_list')"
                v-model="localOpportunity"
              />
            </validation-provider>

            <span @click="showUserModal(false, true)" class="d-flex cursor-pointer">
              <user-item
                label="Responsável pela oportunidade"
                variant="input"
                :selected="selectUser"
                :key="userKey"
                v-model="opportunityUser"
              />
            </span>

            <div style="color: black" class="mb-1">
              Registro {{ isUpdate ? "atualizado" : "criado" }} por

              <span style="color: #046af3">
                {{ user.name }}
              </span>
            </div>

            <validation-provider #default="{ errors }" name="ação" rules="required">
              <small class="text-danger">{{ errors[0] }}</small>

              <v-select
                label="name"
                placeholder="Ação"
                rules="required"
                :reduce="(option) => option.id"
                :options="types"
                v-model="task.task_type_id"
              >
                <template #option="{ name, duration }">
                  <div class="action-selector">
                    <em style="font-style: normal">{{ name }}</em>

                    <div
                      v-if="duration"
                      style="background: #046af3; padding: 3px 5px; border-radius: 3px"
                    >
                      <em style="color: #fff; font-style: normal">
                        {{ duration ? "TPO" : "" }}
                      </em>
                    </div>
                  </div>
                </template>
              </v-select>
            </validation-provider>

            <!--Date -->
            <date-time-picker
              id="start_timestamp"
              name="start_timestamp"
              placeholder="Data da Tarefa"
              format="YYYY-MM-DD"
              formatted="DD/MM/YYYY"
              date-only
              isDisabled
              :minDate="moment()"
              :maxDate="moment().add(18, 'years')"
              v-if="isUpdate"
              v-model="taskDate"
            />

            <!--time -->
            <date-time-picker
              id="start_timestamp"
              format="HH:mm"
              formatted="HH:mm"
              time-only
              isDisabled
              :placeholder="isUpdate ? 'Hora da Tarefa' : 'Insira o Horário'"
              v-if="isUpdate"
              v-model="taskTime"
            />

            <validation-provider
              #default="{ errors }"
              name="Forma de Atendimento"
              vid="task_ended_type"
              rules="required"
            >
              <small class="text-danger">{{ errors[0] }}</small>

              <v-select
                id="task_ended_type"
                name="task_ended_type"
                label="name"
                placeholder="Qual foi a forma de atendimento?"
                rules="required"
                :reduce="(option) => option.id"
                :options="endedTypes"
                :key="taskEndedTypeKey"
                v-model="task.interaction_type_id"
              />
            </validation-provider>

            <div
              :class="`ended-scheduled-check ${task.ended_scheduled ? '' : 'mb-1'}`"
              :style="`border-bottom: ${task.ended_scheduled ? 'none' : '1px solid #5A9DF7'}; 
              
                                                                border-bottom-left-radius: ${
                                                                  task.ended_scheduled
                                                                    ? '0px'
                                                                    : '3px'
                                                                }; 
              
                                                                border-bottom-right-radius: ${
                                                                  task.ended_scheduled
                                                                    ? '0px'
                                                                    : '3px'
                                                                };`"
              @click="toggleEndedScheduled()"
              v-if="!isUpdate"
            >
              <i class="icon-calendar mr-1" />

              <div>Realizar Agendamento</div>
            </div>

            <b-collapse id="collapse-1" class="mb-1" v-model="task.ended_scheduled">
              <div
                v-if="task.ended_scheduled && !isUpdate"
                style="
                  border: 1px solid #046af3;
                  border-top: none;
                  border-bottom-left-radius: 3px;
                  border-bottom-right-radius: 3px;
                  padding: 10px;
                "
              >
                <span @click="showUserModal(true)" class="d-flex">
                  <user-item
                    label="Responsável pela oportunidade"
                    variant="input"
                    :selected="selectUser"
                    :key="userKey"
                    v-model="opportunityUser"
                  />
                </span>

                <validation-provider
                  #default="{ errors }"
                  name="ação"
                  vid="actions"
                  rules="required"
                >
                  <small class="text-danger">{{ errors[0] }}</small>

                  <v-select
                    label="name"
                    placeholder="Ação"
                    :reduce="(option) => option.id"
                    :options="types"
                    v-model="taskTypeForScheduling"
                  >
                    <template #option="{ name, duration }">
                      <div class="action-selector">
                        <em style="font-style: normal">{{ name }}</em>

                        <div
                          v-if="duration"
                          style="background: #046af3; padding: 3px 5px; border-radius: 3px"
                        >
                          <em style="color: #fff; font-style: normal">
                            {{ duration ? "TPO" : "" }}
                          </em>
                        </div>
                      </div>
                    </template>
                  </v-select>
                </validation-provider>

                <validation-provider
                  #default="{ errors }"
                  name="Data da Tarefa"
                  vid="taskDate"
                  rules="required"
                >
                  <small class="text-danger">{{ errors[0] }}</small>
                  <!--Date -->
                  <date-time-picker
                    id="taskDate"
                    placeholder="Data da Tarefa"
                    format="YYYY-MM-DD"
                    formatted="DD/MM/YYYY"
                    date-only
                    :minDate="moment()"
                    :maxDate="moment().add(18, 'years')"
                    v-model="taskDateForScheduling"
                  />
                </validation-provider>

                <validation-provider
                  #default="{ errors }"
                  name="Hora da Tarefa"
                  vid="taskTime"
                  rules="required"
                >
                  <small class="text-danger">{{ errors[0] }}</small>
                  <!--time -->
                  <date-time-picker
                    id="taskTime"
                    format="HH:mm"
                    formatted="HH:mm"
                    time-only
                    placeholder="Insira o Horário"
                    v-model="taskTimeForScheduling"
                  />
                </validation-provider>

                <jitsi-button
                  @jitsiObservation="jitsiObservation($event)"
                  :localOpportunity="localOpportunity"
                  :taskTime="taskTimeForScheduling"
                  :taskDate="taskDateForScheduling"
                  :task="task"
                />
              </div>
            </b-collapse>

            <!--Description -->
            <textarea
              :maxlength="1000"
              :key="descriptionKey"
              class="observation"
              rows="4"
              max-rows="5"
              placeholder="💬 Observações"
              v-model="task.description"
            />

            <!-- Form Actions -->
            <div class="action-buttons">
              <b-button v-if="isUpdate" @click="deleteTask()" variant="danger"> Deletar </b-button>

              <b-button v-if="!fromExternalPhone && !isUpdate" @click="close()">
                Cancelar
              </b-button>

              <b-button variant="primary" type="submit" @click="save" v-if="!hasRequest">
                {{ saveLabel }}
              </b-button>

              <b-button variant="disabled" class="btn-disabled" v-else>
                <b-spinner v-if="hasRequest" /> Aguarde...
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>

      <div class="form-body" title="Agendamento" v-if="!isRegistry">
        <validation-observer ref="taskScheduleValidation">
          <b-form @submit.prevent>
            <legend v-if="isUpdate">Atualize sua tarefa agendada.</legend>

            <validation-provider
              #default="{ errors }"
              name="Oportunidade"
              vid="localOpportunity"
              rules="required"
            >
              <small class="text-danger">{{ errors[0] }}</small>

              <opportunity-item
                class="costumer-avatar"
                single-row
                variant="input"
                showClear
                selectionEnabled
                input
                id="localOpportunity"
                selectionLabel="Selecionar Oportunidade..."
                :opportunity="localOpportunity"
                @clear="clearOpportunity"
                @click="openModal('bv-opportunity_list')"
                v-model="localOpportunity"
              />
            </validation-provider>

            <span @click="showUserModal(false)" class="d-flex">
              <user-item
                label="Responsável pela oportunidade"
                variant="input"
                :selected="selectUser"
                :key="userKey"
                v-model="opportunityUser"
              />
            </span>

            <div style="color: black" class="mb-1">
              Agendamento {{ isUpdate ? "atualizado" : "criado" }} por

              <span style="color: #046af3">
                {{ user.name }}
              </span>
            </div>

            <validation-provider #default="{ errors }" name="ação" rules="required">
              <small class="text-danger">{{ errors[0] }}</small>

              <v-select
                label="name"
                placeholder="Ação"
                rules="required"
                :reduce="(option) => option.id"
                :options="types"
                v-model="task.task_type_id"
              >
                <template #option="{ name, duration }">
                  <div class="action-selector">
                    <em style="font-style: normal">{{ name }}</em>

                    <div
                      v-if="duration"
                      style="background: #046af3; padding: 3px 5px; border-radius: 3px"
                    >
                      <em style="color: #fff; font-style: normal">
                        {{ duration ? "TPO" : "" }}
                      </em>
                    </div>
                  </div>
                </template>
              </v-select>
            </validation-provider>

            <div class="date-time-content">
              <validation-provider
                #default="{ errors }"
                name="Data da Tarefa"
                vid="taskDate"
                rules="required"
              >
                <small class="text-danger">{{ errors[0] }}</small>

                <date-picker
                  class="task-date"
                  placeholder="Data da Tarefa"
                  format="DD/MM/YYYY"
                  v-model="taskDate"
                  :default-value="moment()"
                  value-type="YYYY-MM-DD"
                />
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Hora da Tarefa"
                vid="taskTime"
                rules="required"
              >
                <small class="text-danger">{{ errors[0] }}</small>

                <date-picker
                  class="task-time"
                  type="time"
                  :placeholder="isUpdate ? 'Hora da Tarefa' : 'Insira o Horário'"
                  format="HH:mm"
                  v-model="taskTime"
                  value-type="HH:mm"
                  :minute-step="30"
                />
              </validation-provider>
            </div>

            <jitsi-button
              @jitsiObservation="jitsiObservation($event)"
              :localOpportunity="localOpportunity"
              :taskTime="taskTime"
              :taskDate="taskDate"
              :task="task"
            />

            <textarea
              :style="`margin-bottom:' ${isCompleted ? '10px' : '80px'}`"
              :key="descriptionKey"
              :maxlength="1000"
              class="observation"
              rows="4"
              max-rows="5"
              placeholder="💬 Observações"
              v-model="task.description"
            />

            <div v-if="isCompleted">
              <label>Observação da Conclusão</label>

              <textarea
                :maxlength="1000"
                class="mb-4 observation"
                rows="4"
                max-rows="5"
                v-model="task.ended_description"
              />
            </div>

            <div class="action-buttons">
              <b-button v-if="isUpdate" @click="deleteTask()" variant="danger"> Deletar </b-button>

              <b-button v-if="!fromExternalPhone && !isUpdate" @click="close()">
                Cancelar
              </b-button>

              <b-button variant="primary" type="submit" @click="save" v-if="!hasRequest">
                {{ saveLabel }}
              </b-button>

              <b-button variant="disabled" class="btn-disabled" v-else>
                <b-spinner v-if="hasRequest" /> Aguarde...
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>

      <b-modal id="bv-list_users" class="modal-dialog" hide-footer hide-header>
        <div class="d-block text-center">
          <user-list
            @closed="hideModal('bv-list_users')"
            :isFromScheduling="isFromScheduling"
            @selected="selectUser"
            @selectUserScheduling="selectUserScheduling"
          />
        </div>
      </b-modal>

      <b-modal id="bv-opportunity_list" class="modal-dialog" hide-footer hide-header>
        <div class="d-block text-center">
          <opportunity-list
            @closed="hideModal('bv-opportunity_list')"
            @selected="selectOpportunity"
            :fromTaskModal="true"
          />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import "animate.css";
import { BForm, BButton, BSpinner, BCollapse } from "bootstrap-vue";
import CardTopInfoComponent from "@/views/components/Omnichannel/CardTopInfoComponent.vue";
import DateTimePicker from "@/views/forms/components/DateTimePicker.vue";
import JitsiButton from "./components/JitsiButton.vue";
import moment from "moment";
import OpportunityItem from "../components/OpportunityItem.vue";
import opportunityList from "../components/ListOpportunities";
import { required } from "@validations";
import TaskTopInfo from "./components/TaskTopInfo.vue";
import UserItem from "../components/UserItem.vue";
import UserList from "../components/UserList";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    BButton,
    BCollapse,
    BForm,
    BSpinner,
    CardTopInfoComponent,
    DateTimePicker,
    JitsiButton,
    OpportunityItem,
    opportunityList,
    TaskTopInfo,
    UserItem,
    UserList,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    DatePicker,
    LottieAnimation,
  },

  props: {
    task: {
      type: Object,
      default: () => ({
        name: "",
        start_timestamp: 0,
        user_id: 1,
        opportunity: {
          id: 1,
          name: "",
        },
      }),
      required: false,
    },

    createdFrom: {
      type: String,
      default: null,
      required: false,
    },

    parentId: {
      type: Number,
      default: null,
      required: false,
    },

    opportunity: {
      type: Object,
      default: null,
      required: false,
    },

    isUpdate: {
      type: Boolean,
      default: false,
      required: false,
    },

    currentTimestamp: {
      type: Number,
      default: moment().unix(),
      required: false,
    },

    fromTaskLocation: {
      type: Boolean,
      default: false,
      required: false,
    },

    fromTaskWhatsapp: {
      type: Boolean,
      default: false,
      required: false,
    },

    fromTaskEmail: {
      type: Boolean,
      default: false,
      required: false,
    },

    alternativeTitle: {
      type: String,
      default: "",
      required: false,
    },

    isRegistryProp: {
      type: Boolean,
      default: true,
      required: false,
    },

    fromExternalPhone: {
      type: Boolean,
      default: false,
      required: false,
    },

    opportunity: {
      default: null,
      required: false,
    },

    lockOpportunity: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      taskDate: null,
      taskTime: moment().format("HH:mm"),
      localOpportunity: null,
      localCreatedFrom: null,
      localParentId: null,
      isFromScheduling: false,
      isRegistry: this.isRegistryProp,
      isTheStepBeingChanged: false,
      hasRequest: false,
      required,
      taskTypeForScheduling: null,
      taskDateForScheduling: moment.unix(this.currentTimestamp).format("YYYY-MM-DD"),
      taskTimeForScheduling: moment().format("HH:mm"),
      userKey: 0,
      taskEndedTypeKey: 0,

      opportunityUser: null,
      descriptionKey: 0,
      minuteInterval: 30,

      hasOpportunityRequest: false,

      newestChat: {},
    };
  },

  created() {
    this.fetchTaskActions();

    this.insertOpportunityOnTask();
  },

  computed: {
    user() {
      return this.$store.getters["app/getUserData"];
    },

    workspace() {
      return this.$store.getters["app/getUserWorkspace"];
    },

    types() {
      return this.$store.getters["calendarStore/getActions"];
    },

    endedTypes() {
      return this.$store.getters["calendarStore/getEndedTypes"];
    },

    isCompleted() {
      return this.task.status === "Completed";
    },

    hasParent() {
      return this.localCreatedFrom === "Opportunity";
    },

    title() {
      const wordUpdate = this.isRegistry ? "Registrada" : "Agendada";

      const text = this.isUpdate
        ? `Atualizar Tarefa ${wordUpdate}`
        : this.isRegistry
        ? "Registre uma tarefa realizada"
        : "Agendar Tarefa";

      return text;
    },

    saveLabel() {
      let label = "Agendar";

      label = this.isRegistry ? "Registrar" : label;

      label = this.isUpdate ? "Atualizar" : label;

      label = this.isCompleted && !this.isRegistry ? "Reabrir" : label;

      return label;
    },

    opportunityUpdateEvent() {
      return this.$store.getters["bmodalEvents/getOpportunityCreationInsideTaskEvent"];
    },
  },

  watch: {
    opportunityUpdateEvent() {
      this.refreshOpportunity();
    },

    localOpportunity(newSelected, oldSelected) {
      if (newSelected != oldSelected) {
        if (this.localOpportunity && this.workspace.role == "manager") {
          this.opportunityUser = this.localOpportunity.user;
        } else {
          this.opportunityUser = this.user;
        }
      }
    },

    "task.task_type_id": function (newSelected, oldSelected) {
      if (newSelected != oldSelected) {
        this.setQuickEndedType();
      }
    },
  },

  methods: {
    fetchTaskActions() {
      if (this.endedTypes.length == 0) {
        this.$store.dispatch("calendarStore/fetchEndedTypes");
      }

      if (this.types.length == 0) {
        this.$store.dispatch("calendarStore/fetchTaskTypes");
      }
    },

    close() {
      this.$emit("closed");
    },

    getNewestChatInfo() {
      if (!this.task.opportunity.dialogs?.length) {
        return;
      }

      this.newestChat = this.task.opportunity.dialogs[0];
    },

    jitsiObservation(meetingMessage) {
      if (!this.task.description) {
        this.task.description = meetingMessage;
      } else {
        this.task.description += meetingMessage;
      }

      this.descriptionKey++;
    },

    setQuickEndedType() {
      const selectedTaskType = this.types.find((type) => type.id == this.task.task_type_id);

      const endedType = this.endedTypes.find(
        (type) => type.id == selectedTaskType.default_interaction_type_id
      );

      this.task.interaction_type_id = endedType?.id;
      this.taskEndedTypeKey += 1;
    },

    assignDefaultValuesOfProperties() {
      this.localOpportunity = this.opportunity;
      this.localCreatedFrom = this.createdFrom;
      this.localParentId = this.parentId;

      if (!this.isUpdate) {
        if (this.workspace.role == "manager") {
          this.opportunityUser = this.localOpportunity ? this.localOpportunity.user : this.user;
        } else {
          this.opportunityUser = this.user;
        }

        this.task.start_timestamp = moment.unix(this.currentTimestamp).format("YYYY-MM-DD");

        if (this.localOpportunity) {
          this.localCreatedFrom = "Opportunity";
          this.localParentId = this.opportunity.id;
          this.task.opportunity = this.opportunity;
        }
      } else {
        if (this.task.opportunity) {
          this.localCreatedFrom = "Opportunity";
          this.localParentId = this.task.opportunity.id;
          this.localOpportunity = this.task.opportunity;
        }
        this.taskTime = moment(this.task.start_timestamp).format("HH:mm");

        this.isRegistry = this.task.is_registry == true;
      }

      this.taskDate = moment(this.task.start_timestamp).format("YYYY-MM-DD");
    },

    openModal(modal) {
      this.$bvModal.show(modal);
    },

    toggleEndedScheduled() {
      this.task.ended_scheduled = !this.task.ended_scheduled;
    },

    hideModal(modal) {
      this.$bvModal.hide(modal);
    },

    clearOpportunity() {
      if (this.lockOpportunity) return;

      this.localOpportunity = null;
      this.task.opportunity = null;
    },

    save() {
      let formRefs = {};

      if (this.isRegistry) {
        formRefs = this.$refs.taskRegistryValidation;
      } else {
        formRefs = this.$refs.taskScheduleValidation;
      }

      formRefs.validate().then((success) => {
        if (success) {
          this.hasRequest = true;
          // this.taskTime = moment(this.taskTime, "HH:mm");

          const currentTask = this.task;

          currentTask.is_registry = this.isRegistry ? 1 : 0;
          currentTask.start_timestamp = this.taskDate + " " + this.taskTime;
          currentTask.parent_type = this.localCreatedFrom;
          currentTask.parent_id = this.localParentId;

          if (!this.isRegistry) {
            currentTask.status = "NotStarted";
            currentTask.interaction_type_id = null;
            currentTask.ended_description = null;
          }

          delete currentTask.ended_at;

          if (this.task.ended_scheduled && !this.isUpdate) {
            if (success) {
              this.createARegistryFollowedByASchedule(currentTask);
            }

            return;
          }

          if (this.isRegistry) {
            currentTask.user_id = this.user.id;
          } else {
            currentTask.user_id = this.opportunityUser.id;
          }

          this.$store
            .dispatch("calendarStore/save", currentTask)
            .then((response) => {
              const taskData = response.data;

              if (this.localOpportunity?.user_id == this.opportunityUser?.id) {
                this.$emit("saved", taskData);

                return;
              }

              if (this.localOpportunity) {
                this.localOpportunity.user_id = this.opportunityUser?.id;
                this.localOpportunity.user = this.opportunityUser;
              }

              this.$store
                .dispatch("opportunityStore/assign", this.localOpportunity)
                .then(() => {
                  this.$emit("saved", taskData);
                })
                .catch((error) => {
                  this.hasSaveRequest = false;
                });
            })
            .catch((error) => {
              if (error.response?.data?.message == '"Start Timestamp" deve ser um número inteiro') {
                this.$bvToast.toast(
                  "Você deve selecionar um horário e data válidos para o agendamento!",
                  {
                    title: `Erro ${error.response.status}`,
                    autoHideDelay: 3000,
                    variant: "danger",
                    toaster: "b-toaster-top-left",
                    solid: true,
                  }
                );
              } else {
              }
            })
            .finally(() => {
              this.hasRequest = false;
            });
        }
      });
    },

    setDefaultValueOfSchedule(task) {
      const scheduled = task;

      scheduled.user = this.opportunityUser;
      scheduled.user_id = this.opportunityUser.id;
      scheduled.task_type_id = this.taskTypeForScheduling;
      scheduled.status = "NotStarted";
      scheduled.interaction_type_id = null;
      scheduled.ended_description = task.ended_description;

      this.taskTimeForScheduling = moment(this.taskTimeForScheduling, "HH:mm").format("HH:mm");

      scheduled.start_timestamp = this.taskDateForScheduling + " " + this.taskTimeForScheduling;

      scheduled.is_registry = 0;

      return scheduled;
    },

    setDefaultValueOfRegistry(task) {
      const registry = task;
      registry.is_registry = 1;
      registry.user_id = this.user.id;

      return registry;
    },

    createARegistryFollowedByASchedule(currentTask) {
      const registry = this.setDefaultValueOfRegistry(Object.assign({}, currentTask));

      const scheduled = this.setDefaultValueOfSchedule(Object.assign({}, currentTask));

      this.$store
        .dispatch("calendarStore/save", registry)
        .then(() => {
          this.$store
            .dispatch("calendarStore/save", scheduled)
            .then((response) => {
              const taskData = response.data;

              if (this.localOpportunity?.user_id == this.opportunityUser?.id) {
                this.$emit("saved", taskData);

                return;
              }

              this.localOpportunity.user_id = this.opportunityUser.id;
              this.localOpportunity.user = this.opportunityUser;

              this.$store
                .dispatch("opportunityStore/assign", this.localOpportunity)
                .then(() => {
                  this.$emit("saved", taskData);
                })
                .catch((error) => {
                  this.hasSaveRequest = false;
                });
            })
            .finally(() => {
              this.hasRequest = false;
            });
        })
        .catch((error) => {
          this.hasRequest = false;
        });
    },

    showUserModal(isFromScheduling, blockSales = false) {
      if (blockSales && this.workspace.role == "salesRep") {
        return;
      }

      if (isFromScheduling) {
        this.isFromScheduling = true;
      }

      this.openModal("bv-list_users");
    },

    selectUser(user) {
      this.opportunityUser = user;
      this.hideModal("bv-list_users");
      this.userKey += 1;
    },

    selectUserScheduling(user) {
      this.opportunityUser = user;
      this.isFromScheduling = false;
      this.hideModal("bv-list_users");
      this.userKey += 1;
    },

    hideAndUpdate(modal) {
      this.isTheStepBeingChanged = true;
      this.hideModal(modal);
      this.isTheStepBeingChanged = false;
    },

    selectOpportunity(opportunity) {
      this.localOpportunity = opportunity;

      if (this.localOpportunity) {
        this.localCreatedFrom = "Opportunity";
        this.localParentId = this.localOpportunity.id;
        this.task.opportunity = this.localOpportunity;
      }

      this.hideModal("bv-opportunity_list");
    },

    refreshOpportunity() {
      this.$store
        .dispatch("opportunityStore/fetchOne", this.localOpportunity.id)
        .then((response) => {
          this.localOpportunity = response.data;

          this.hideModal("bv-edit_task_opportunity");
        });
    },

    deleteTask() {
      let sure = confirm("Tem certeza disso?");

      if (sure) {
        this.$store.dispatch("calendarStore/delete", this.task).then(() => {
          this.$emit("deleted");
        });
      }
    },

    insertOpportunityOnTask() {
      if (!this.task?.parent_id) {
        this.assignDefaultValuesOfProperties();

        return;
      }

      this.$store
        .dispatch("opportunityStore/fetchOne", this.task.parent_id)
        .then((response) => {
          this.task.opportunity = response.data;

          this.assignDefaultValuesOfProperties();

          this.getNewestChatInfo();
        })
        .catch((error) => {
          this.$emit("closed");
        })
        .finally(() => {
          this.hasOpportunityRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#taskForm {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;

  .fixed-infos {
    position: sticky;
  }

  .form-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;

    .observation::placeholder {
      margin: 0 auto;
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translateX(35%) translateY(25%);
      overflow: visible;
    }

    .observation {
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #d8d6de;
      color: #6e6b7b;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        margin: 4px 0;
        border-radius: 13px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 13px;
        border: 1px solid transparent;
        background-clip: content-box;
        box-shadow: inset 0 0 0 7px #6e6b7b;
      }
    }

    .observation:focus {
      outline: none;
    }

    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: fixed;
      height: 55px;
      width: 100%;
      max-width: 500px;
      left: 0;
      right: 0;
      bottom: 0;
      border-top: 1px solid #e5e5e5;
      background: #fff;
    }

    legend {
      color: #000;
      text-align: left;
      font-size: 1em;
      padding-top: 0.48em;
      margin-bottom: 1em;
    }
  }

  .ended-scheduled-check {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    border: 1px solid #046af3;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #046af3;
    font-weight: 500;
    padding-left: 10px;
  }

  .action-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 27px;
  }
}

.date-time-content {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  .task-date,
  .task-time {
    width: 100%;
    height: 45px;

    :nth-child(1) {
      height: 100%;
    }
  }

  .task-date {
    padding-right: 5px;
  }

  .task-time {
    padding-left: 5px;
  }
}

.loading-task {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
</style>
