<template>
  <div class="opportunity-tma" :class="{ tmaDatesWarning: showWarningTma && !opportunity.tma }">
    <span id="createdDate">
      Criado em:

      <b>
        {{
          this.$service.dateFormat(this.opportunity.created_at, "YYYY-MM-DD HH:mm:ss", "DD/MM/YY")
        }}
        às
        {{ this.$service.dateFormat(this.opportunity.created_at, "YYYY-MM-DD HH:mm:ss", "HH:mm") }}
      </b>
    </span>

    <div id="updatedDate">
      <img
        v-if="showAlertTma"
        :src="`${require('@/assets/images/icons/thermometer-red.svg')}`"
        class="thermomether"
        alt="thermometer"
      />

      <img
        v-else-if="showDefaultTma"
        :src="`${require('@/assets/images/icons/check-group.svg')}`"
        class="checkGroup"
        alt="check-group"
      />

      <img
        v-else-if="showWarningTma"
        :src="`${require('@/assets/images/icons/check-group-red.svg')}`"
        class="checkGroup"
        alt="check-group"
      />

      <div>
        <span
          v-if="!opportunity.tma"
          :class="{
            showAlertTma: showAlertTma,
            hideAlertTma: !showAlertTma,
          }"
        >
          {{ tma }} sem interação
        </span>

        <span v-else-if="opportunity.tma && opportunity.tma < 600" class="hideAlertTma">
          Atendido em {{ tma }}
        </span>

        <span v-else-if="opportunity.tma && opportunity.tma > 600" class="showAlertTma">
          Atendido em {{ tma }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import tmaServices from "@/services/tmaServices";

export default {
  props: {
    opportunity: {
      type: Object,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      tma: false,

      showAlertTma: false,
      showWarningTma: false,
      showDefaultTma: false,
    };
  },

  created() {
    this.setFormatedTma();
  },

  methods: {
    setFormatedTma() {
      let tma = this.opportunity.tma;

      if (!tma) {
        let startTimestamp = this.opportunity.recreated_at ?? this.opportunity.created_at;
        startTimestamp = new Date(startTimestamp).getTime() / 1000;

        let currentTimestamp = new Date().getTime() / 1000;
        let calculatedTmaWithNoInteraction = currentTimestamp - startTimestamp;

        this.setTmaWarnings(calculatedTmaWithNoInteraction);
        this.tma = tmaServices.formatter(calculatedTmaWithNoInteraction);

        return;
      }

      this.setTmaWarnings(tma);

      this.tma = tmaServices.formatter(tma);
    },

    setTmaWarnings(tma) {
      const tmaIndentificator = tmaServices.formatterWithIndentificator(tma);

      if (tmaIndentificator == "acceptableTime") {
        this.showAlertTma = false;
        this.showWarningTma = false;
        this.showDefaultTma = true;

        if (!this.opportunity.tma) {
          this.showDefaultTma = false;
        }
      } else if (tmaIndentificator == "attentionTime") {
        this.showWarningTma = false;
        this.showAlertTma = true;
        this.showDefaultTma = false;

        if (this.opportunity.tma && tma > 600 && tma < 1800) {
          this.showWarningTma = true;
          this.showAlertTma = false;
          this.showDefaultTma = false;
        }
      } else if (tmaIndentificator == "exceededTime") {
        this.showWarningTma = false;
        this.showAlertTma = true;
        this.showDefaultTma = false;

        if (this.opportunity.tma && tma > 600) {
          this.showWarningTma = true;
          this.showAlertTma = false;
          this.showDefaultTma = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.opportunity-tma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border: 1px solid #dcdbe2;
  border-radius: 6px;
  padding: 0 12px;

  @media (max-width: 450px) {
    padding: 0 2px;
  }

  #createdDate {
    font-size: 12px;
    justify-self: flex-start;
  }

  #updatedDate {
    display: flex;
    flex-direction: row;

    .thermomether {
      width: 12px;
      margin-right: 15px;

      @media (max-width: 450px) {
        margin-right: 8px;
      }
    }

    .checkGroup {
      width: 18px;
      margin-right: 15px;
    }

    div {
      display: flex;
      align-items: center;

      .showAlertTma {
        font-size: 13px;
        color: #f7685b;
      }

      .hideAlertTma {
        font-size: 13px;
        color: #046af3;
      }
    }
  }

  .tmaDatesWarning {
    border: 1px solid #f7685b;
  }
}
</style>
