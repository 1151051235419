<template>
  <div @keydown="handleKeydown" class="update-opportunity">
    <close-opportunity-from-chat
      v-if="showCloseChat"
      @closeHalfModal="closeHalfModal()"
      @refresh="$emit('saved')"
      :fromOpportunityDesktop="true"
      :fromOmnichannelDesktop="isFromOmnichannelDesktop"
      :opportunity="unchangedOpportunity"
      :newestChat="newestChat"
    />

    <validation-observer
      v-if="!hasSalesStageRequest && !hasOpportunityRequest"
      class="form-holder nice-Vscroll"
      ref="formValidation"
    >
      <card-top-info-component
        @new-task="openNewTaskModal()"
        @singleVoipCall="singleVoipCall()"
        @closeByChat="showCloseByChat()"
        :opportunity="unchangedOpportunity"
        :isFromExternalCall="isFromExternalCall"
        :fromOmnichannelDesktop="isFromOmnichannelDesktop"
        :newestChat="newestChat"
        :editable="true"
        v-model="name"
      />

      <tags-selector v-model="tags" />

      <opportunity-tma :opportunity="unchangedOpportunity" />

      <qualification v-model="probability" />

      <div class="low-gap-sub-group">
        <opportunity-won-lost-buttons
          @set-won="handleCloseDeal('Won')"
          @set-lost="handleCloseDeal('Lost')"
          :opportunity="unchangedOpportunity"
        />

        <div
          v-if="
            unchangedOpportunity.state_type == 'Won' &&
            platform?.integrated_enroll == 'imNic' &&
            checkContractStatus() &&
            checkImNicToken()
          "
          @click="openEnroll"
          class="enrollment-button"
        >
          <i class="icon-upload-file enroll-icon" />

          Gerar Matrícula
        </div>

        <contract-status
          v-if="unchangedOpportunity.contract"
          :contract="unchangedOpportunity.contract"
          :dependent="{
            isResponsable: unchangedOpportunity.is_responsible,
            studentName: unchangedOpportunity.student_name,
            studentDocument: unchangedOpportunity.student_document,
          }"
        />

        <div v-if="unchangedOpportunity.state_type == 'Lost'" class="lost-reason-description">
          <div class="lost-title">
            {{ getFormatedClosedType(unchangedOpportunity.closed_type) }}.
          </div>

          <div class="lost-description">
            {{ unchangedOpportunity.closed_description || "Sem descrição" }}
          </div>
        </div>

        <opportunity-step
          @updateTaskCount="handleUpdateTaskCount()"
          :sales-stages="saleStages"
          :opportunity="unchangedOpportunity"
          ruled-stage-change
          v-model="sales_stage_id"
        />

        <b-button
          @click="showTaskOptionsCollapse = !showTaskOptionsCollapse"
          class="add-task-button"
          variant="primary"
        >
          <feather-icon :icon="showTaskOptionsCollapse ? 'MinusCircleIcon' : 'PlusCircleIcon'" />

          Adicionar Tarefa
        </b-button>

        <b-collapse id="collapse-1" v-model="showTaskOptionsCollapse">
          <div class="task-collapse">
            <span class="task-collapse-title">Deseja registrar ou agendar uma tarefa? </span>

            <div @click="openNewTaskModal(true)" class="task-buttons task-register">
              <span>Registrar Tarefa</span>

              <i class="icon-keyboard-arrow-right ml-1" style="font-size: 10px" />
            </div>

            <div @click="openNewTaskModal(false)" class="task-buttons task-schedule">
              <span> Agendar Tarefa </span>

              <i class="icon-keyboard-arrow-right ml-1" style="font-size: 10px" />
            </div>
          </div>
        </b-collapse>
      </div>

      <div
        v-if="isFromExternalCall && external_phone_status != 'paused'"
        class="call-component-holder"
      >
        <div class="call-ongoing">
          Em atendimento

          <div class="white-green-circle" />
        </div>

        <b-button @click="terminateCall" class="dial-stop" variant="danger">
          <feather-icon icon="PhoneOffIcon" size="18" />

          Finalizar
        </b-button>
      </div>

      <line-component color="#E5E5E5" />

      <b-tabs fill>
        <b-tab title="Dados" class="b-tab-style">
          <legend>Informações</legend>

          <validation-provider
            #default="{ errors }"
            name="Nome do Contato"
            rules="required"
            vid="name"
          >
            <small class="v-err text-danger">{{ errors[0] }}</small>

            <input-field
              id="contact_name"
              placeholder="Nome Completo"
              borderRadius="5px"
              type="text"
              trim
              v-model="contact.name"
            />
          </validation-provider>

          <validation-provider #default="{ errors }" name="celular" rules="required" vid="phone">
            <small class="v-err text-danger">{{ errors[0] }}</small>

            <small class="text-danger" v-if="needDDD"> O número precisa conter algum DDD </small>

            <vue-phone-number-input
              @update="setMobileNumberInformation"
              :border-radius="0"
              :color="colorOfNumberInput"
              :default-country-code="countryCode"
              :translations="{
                countrySelectorLabel: 'Código do país',
                countrySelectorError: 'Escolha um país',
                phoneNumberLabel: 'Celular',
                example: 'Exemplo :',
              }"
              show-code-on-list
              class="phone-input"
              size="lg"
              valid-color="#046af3"
              v-model="phone_number"
            />
          </validation-provider>

          <input-field
            id="email"
            placeholder="email@dominio.com.br"
            borderRadius="5px"
            type="email"
            trim
            v-model="contact.email"
          />

          <line-component color="#E5E5E5" />

          <input-field
            @editClick="redirectToContacts()"
            id="contact"
            placeholder="Contato vinculado"
            is-static
            trim
            v-model="contact.name"
          />

          <span @click="showModalWithRoleCheck('bv-tranfer-opportunity')" class="cursor-pointer">
            <workspace-item
              label="Workspace"
              class="form-boxes"
              variant="input"
              v-model="currentWorkspace"
            />
          </span>

          <validation-provider
            #default="{ errors }"
            name="Responsável"
            rules="required"
            vid="currentUser"
          >
            <small class="v-err text-danger mb-1">{{ errors[0] }}</small>

            <span @click="showModalWithRoleCheck('bv-list_users')">
              <user-item
                label="Responsável"
                class="form-boxes"
                variant="input"
                v-model="currentUser"
              />
            </span>
          </validation-provider>

          <user-item
            v-if="visit_generator && platform?.has_street_generation"
            label="Gerador de Visita"
            class="form-boxes"
            variant="input"
            disabled
            v-model="visit_generator"
          />

          <small v-if="hasProductPriceError && (!product || !price)" class="v-err text-danger">
            {{ requiredProductError }}
          </small>

          <product-item
            @selected="selectProduct($event)"
            @clear="unsetProduct()"
            :class="{ 'error-border': hasProductPriceError && !product }"
            :products="product"
            id="productItem"
            singular-return
          />

          <b-form-group
            :class="{ 'error-border': hasProductPriceError && !price }"
            class="price-field"
          >
            <money
              @blur.native="checkForMoneyError(price)"
              id="price"
              class="form-control price"
              placeholder="Venda"
              trim
              v-model="price"
            />

            <label for="price" class="floating"> {{ platform?.terms?.price_term }}: </label>
          </b-form-group>

          <v-select
            v-if="$service.isIm()"
            :options="opportunity_types"
            :reduce="(option) => option.id"
            id="opportunity_type"
            label="text"
            placeholder="Tipo"
            v-model="opportunity_type_id"
          />

          <legend>Informações Complementares</legend>

          <date-time-picker
            id="start_timestamp"
            format="YYYY-MM-DD"
            formatted="DD/MM/YYYY"
            placeholder="Data de Nascimento"
            date-only
            no-button-now
            v-model="contact.birthdate"
          />

          <input-field
            :mask="['###.###.###-##']"
            id="CPF"
            placeholder="CPF"
            borderRadius="5px"
            type="text"
            trim
            v-model="contact.document"
          />

          <input-field
            id="RG"
            placeholder="RG"
            borderRadius="5px"
            type="text"
            trim
            v-model="contact.id_number"
          />

          <legend>Endereço</legend>

          <input-field
            id="cep"
            placeholder="CEP"
            borderRadius="5px"
            type="text"
            trim
            v-model="contact.postal_code"
          />

          <input-field
            id="address_line_1"
            placeholder="Endereço"
            borderRadius="5px"
            type="text"
            trim
            v-model="contact.address_line1"
          />

          <input-field
            id="address_line_2"
            placeholder="Complemento"
            borderRadius="5px"
            type="text"
            trim
            v-model="contact.address_line2"
          />

          <v-select
            label="title"
            placeholder="Estado"
            :options="states"
            :reduce="(option) => option.id"
            v-model="contact.state_code"
          />

          <input-field
            id="cidade"
            placeholder="Cidade"
            borderRadius="5px"
            type="text"
            trim
            v-model="contact.city_name"
          />

          <input-field
            id="bairro"
            placeholder="Bairro"
            borderRadius="5px"
            type="text"
            trim
            v-model="contact.neighborhood"
          />

          <legend>Redes Sociais</legend>

          <input-field
            id="instagram"
            placeholder="@instagram"
            borderRadius="5px"
            type="text"
            trim
            v-model="contact.instagram"
          />

          <input-field
            id="facebook"
            placeholder="@facebook"
            borderRadius="5px"
            type="text"
            trim
            v-model="contact.facebook"
          />

          <input-field
            id="linkedin"
            placeholder="@linkedIn"
            borderRadius="5px"
            type="text"
            trim
            v-model="contact.linkedin"
          />

          <div v-if="platform?.responsibleCheck" class="is-responsible">
            <legend>Dependentes</legend>

            <div class="responsibility-checkbox-holder">
              <input type="checkbox" class="custom-checkbox" v-model="is_responsible" />

              Não é aluno?
            </div>
          </div>

          <div v-if="is_responsible" class="is-responsible">
            <validation-provider
              #default="{ errors }"
              name="Nome"
              rules="required"
              vid="student_name"
            >
              <small class="v-err text-danger">{{ errors[0] }}</small>

              <input-field
                id="name"
                placeholder="Nome Completo"
                type="text"
                borderRadius="5px"
                trim
                v-model="student_name"
              />
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="CPF"
              rules="required"
              vid="student_document"
            >
              <small class="v-err text-danger">{{ errors[0] }}</small>

              <input-field
                :mask="['###.###.###-##']"
                id="CPF"
                placeholder="CPF"
                type="text"
                borderRadius="5px"
                trim
                v-model="student_document"
              />
            </validation-provider>
          </div>

          <b-form-textarea
            @blur="checkDescriptionLimit($event)"
            :style="description?.length > 10000 ? 'border-color: #EA5455' : ''"
            placeholder="Observação"
            max-rows="2"
            rows="2"
            v-model="description"
          />
        </b-tab>

        <b-tab title="Origem/Mídia" class="b-tab-style" lazy>
          <legend>Origem</legend>

          <validation-provider
            v-if="isCardManual"
            #default="{ errors }"
            name="Origem"
            rules="required"
            vid="source_type"
          >
            <small class="v-err text-danger">{{ errors[0] }}</small>

            <v-select
              :options="source_types"
              :reduce="(option) => option.id"
              :clearable="false"
              id="source_type"
              label="title"
              placeholder="Origem"
              v-model="source_type"
            />
          </validation-provider>

          <div @click="showBlockedContetError()" v-else class="blocked-content">
            {{ getBlockedContentSourceType(unchangedOpportunity.source_type) }}
          </div>

          <legend>Mídia</legend>

          <validation-provider
            v-if="isCardManual"
            #default="{ errors }"
            name="Mídia"
            rules="required"
            vid="media_id"
          >
            <small class="v-err text-danger">{{ errors[0] }}</small>

            <v-select
              :options="medias"
              :reduce="(option) => option.id"
              :clearable="false"
              id="media"
              label="name"
              placeholder="Mídia"
              v-model="media_id"
            />
          </validation-provider>

          <div @click="showBlockedContetError()" v-else class="blocked-content">
            {{ unchangedOpportunity?.media?.name }}
          </div>

          <input-field
            id="campaign_url"
            placeholder="Site"
            borderRadius="5px"
            type="text"
            trim
            v-model="campaign_url"
          />

          <legend>Campanha</legend>

          <validation-provider #default="{ errors }" name="Campanha" vid="campaign">
            <small class="v-err text-danger">{{ errors[0] }}</small>

            <v-select
              :options="campaigns"
              :reduce="(option) => option.id"
              id="campaign"
              label="name"
              placeholder="Campanha"
              v-model="campaign_id"
            />
          </validation-provider>

          <input-field
            v-if="form_id"
            id="form_id"
            placeholder="ID do Formulário Externo"
            type="text"
            isDisabled
            trim
            v-model="form_id"
          />

          <div
            v-if="role == 'admin'"
            @click="redirectToInternalAdmin()"
            class="edit-lists-redirect"
          >
            <i class="icon-pencil-line" />

            <div>Editar Listas</div>
          </div>
        </b-tab>
      </b-tabs>
    </validation-observer>

    <div v-else class="spinner-holder">
      <b-spinner />
    </div>

    <div class="bottom-menu">
      <b-button v-if="!isFromOmnichannelDesktop" @click="$emit('close')" variant="light">
        Cancelar
      </b-button>

      <b-button @click="updateOpportunity()" variant="primary">
        <b-spinner v-if="hasSaveRequest" small />

        <div v-else>Salvar</div>
      </b-button>
    </div>

    <ClosingOpportunityModals
      v-if="unchangedOpportunity"
      @saveDeal="handleSaveDeal($event)"
      :opportunityProp="unchangedOpportunity"
    />

    <b-modal class="modal-dialog" id="bv-list_users" hide-footer hide-header>
      <user-list @closed="$bvModal.hide('bv-list_users')" @selected="selectUser($event)" />
    </b-modal>

    <b-modal
      id="update-opportunity-task-form-stage-swap"
      class="modal-dialog"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <task-form
        v-if="unchangedOpportunity"
        @saved="
          $bvModal.hide('update-opportunity-task-form-stage-swap'),
            handleUpdateTaskCount(),
            handleCloseDeal(willWinTheOpportunity ? 'Won' : 'Lost')
        "
        @closed="$bvModal.hide('update-opportunity-task-form-stage-swap')"
        :opportunity="unchangedOpportunity"
        :parentId="unchangedOpportunity.id"
        :isRegistryProp="true"
        createdFrom="Opportunity"
      />
    </b-modal>

    <b-modal
      v-if="unchangedOpportunity"
      id="update-opportunity-task-form"
      class="modal-dialog"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <task-form
        @saved="handleTaskSave($event)"
        @closed="$bvModal.hide('update-opportunity-task-form')"
        :opportunity="unchangedOpportunity"
        :parentId="unchangedOpportunity.id"
        :isRegistryProp="isRegistry"
        createdFrom="Opportunity"
      />
    </b-modal>

    <b-modal id="verify-cancel-opportunity" hide-footer hide-header no-close-on-backdrop>
      <verify-cancel
        @save="$bvModal.hide('verify-cancel-opportunity'), updateOpportunity()"
        @close="$bvModal.hide('verify-cancel-opportunity'), $emit('close')"
      />
    </b-modal>

    <b-modal class="modal-dialog" id="bv-tranfer-opportunity" hide-footer hide-header>
      <transfer-workspace
        @transfered="$emit('saved')"
        @closed="$bvModal.hide('bv-tranfer-opportunity')"
        :opportunity="unchangedOpportunity"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCollapse,
  BFormGroup,
  BFormTextarea,
  BSpinner,
  BTabs,
  BTab,
} from "bootstrap-vue";
import moment from "moment";
import { Money } from "v-money";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import CardTopInfoComponent from "@/views/components/Omnichannel/CardTopInfoComponent.vue";
import CloseOpportunityFromChat from "@/views/components/CloseOpportunityFromChat.vue";
import ClosingOpportunityModals from "@/views/components/ClosingOpportunityModals.vue";
import ContractStatus from "@/views/forms/components/ContractStatus.vue";
import DateTimePicker from "@/views/forms/components/DateTimePicker.vue";
import InputField from "@/views/components/InputField.vue";
import Line from "@/views/components/Line.vue";
import OpportunityStep from "@/views/components/OpportunityStep.vue";
import OpportunityTma from "@/views/components/OpportunityTma.vue";
import OpportunityWonLostButtons from "@/views/forms/components/OpportunityWonLostButtons.vue";
import ProductItem from "@/views/components/ProductItem.vue";
import Qualification from "@/views/components/Qualification.vue";
import SaleStageSelector from "@/views/components/kanban/SaleStageSelector.vue";
import TagsSelector from "@/views/components/TagsSelector.vue";
import TaskForm from "@/views/forms/TaskForm.vue";
import TransferWorkspace from "@/views/components/TransferWorkspace.vue";
import UserItem from "@/views/components/UserItem.vue";
import UserList from "@/views/components/UserList.vue";
import VerifyCancel from "@/views/forms/components/VerifyCancel.vue";
import WorkspaceItem from "@/views/components/WorkspaceItem.vue";

export default {
  components: {
    BButton,
    BCollapse,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BTabs,
    BTab,
    Money,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput,

    CardTopInfoComponent,
    CloseOpportunityFromChat,
    ClosingOpportunityModals,
    ContractStatus,
    DateTimePicker,
    InputField,
    OpportunityStep,
    OpportunityTma,
    OpportunityWonLostButtons,
    ProductItem,
    Qualification,
    SaleStageSelector,
    TagsSelector,
    TaskForm,
    TransferWorkspace,
    UserItem,
    UserList,
    VerifyCancel,
    WorkspaceItem,

    "line-component": Line,
  },

  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },

    opportunity_prop: {
      type: Object || null,
      required: false,
      default: null,
    },

    newestChat: {
      type: Object || null,
      required: false,
      default: null,
    },

    isFromOmnichannelDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },

    isFromExternalCall: {
      type: Boolean,
      required: false,
      default: false,
    },

    watchVerifyCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      saleStages: [],

      currentUser: null,
      currentWorkspace: null,
      opportunity: null,
      unchangedOpportunity: null,

      hasSaveRequest: false,
      hasOpportunityRequest: false,
      hasSalesStageRequest: false,
      hasVoipCredentialsRequest: false,

      showCloseChat: false,
      isCardManual: false,
      willWinTheOpportunity: false,
      willLoseTheOpportunity: false,
      hasProductPriceError: false,

      //Opportunity Data
      name: null,
      sales_stage_id: null,
      probability: 40,
      product: null,
      price: 0,
      source_type: null,
      media_id: null,
      campaign_id: null,
      campaign_url: null,
      description: null,
      is_responsible: false,
      student_name: null,
      student_document: null,
      opportunity_type_id: null,
      workspace_id: null,
      visit_generator: null,
      form_id: null,
      closed_type: null,
      closed_description: null,
      trigger_room_status: null,
      tags: [],

      //Contact Data
      contact: {},
      phone_number: null,

      //Phone Number Data
      countryCode: "BR",
      editedPhoneNumber: null,
      colorOfNumberInput: "#d8d6de",
      needDDD: false,

      //Task Data
      currentTask: null,
      formattedTaskTimestamp: null,
      showTaskOptionsCollapse: false,
      isRegistry: true,

      allPossibleAutomaticSources: ["URA", "Bella", "FormularioSite"],

      requiredProductError:
        'O campo "Produto" e "Preço" são obrigatórios para a conclusão da oportunidade como "Ganha".',
    };
  },

  computed: {
    role() {
      return this.$store.getters["app/getUserRole"];
    },

    user() {
      return this.$store.getters["app/getUserData"];
    },

    medias() {
      return this.$store.getters["opportunityStore/getMedias"];
    },

    campaigns() {
      return this.$store.getters["opportunityStore/getCampaigns"];
    },

    states() {
      return this.$store.getters["opportunityStore/getSourceStates"];
    },

    opportunity_types() {
      return this.$store.getters["opportunityStore/getOpportunityTypes"];
    },

    reasons_lost() {
      return this.$store.getters["opportunityStore/getLostReasons"];
    },

    platform() {
      return this.$store.getters["whiteLabel/getPlatform"];
    },

    external_phone_status() {
      return this.$store.getters["externalPhone/getPhoneStatus"];
    },

    source_types() {
      const deepClone = require("lodash");

      let options = deepClone.cloneDeep(this.$store.getters["opportunityStore/getSourceTypes"]);

      let sources = Object.values(options);

      return sources.filter(
        (option) =>
          option.id != "URA" &&
          option.id != "Bella" &&
          option.id != "FormularioSite" &&
          option.id != "Importacao"
      );
    },
  },

  created() {
    this.fetchOpportunity();
    this.fetchSalesStages();
    this.fetchLostReasons();
    this.fetchCampaigns();
    this.fetchMedias();
  },

  watch: {
    watchVerifyCancel() {
      this.handleVerifyCancel();
    },
  },

  methods: {
    openNewTaskModal(isRegistry) {
      this.showTaskOptionsCollapse = false;

      this.isRegistry = isRegistry;

      this.$bvModal.show("update-opportunity-task-form");
    },

    openEnroll() {
      this.$store.dispatch("contract/setOpportunityForContract", this.unchangedOpportunity);

      this.$bvModal.show("bv-enrollment_form");
    },

    showCloseByChat() {
      this.showCloseChat = true;
    },

    showModalWithRoleCheck(modal) {
      if (this.role !== "salesRep") {
        this.$bvModal.show(modal);
      }
    },

    showBlockedContetError() {
      this.$bvToast.toast("Este campo está bloqueado por se tratar de um card externo", {
        title: "Campo Bloqueado",
        variant: "warning",
        solid: true,
      });
    },

    closeHalfModal() {
      this.showCloseChat = false;
    },

    terminateCall() {
      this.$store.commit("externalPhone/TRIGGER_END_CALL");
    },

    checkContractStatus() {
      return (
        !this.unchangedOpportunity.contract ||
        this.unchangedOpportunity.contract?.payment_status == "Canceled"
      );
    },

    checkImNicToken() {
      return !!localStorage.getItem("imNicToken");
    },

    checkIfCardIsManual() {
      if (this.allPossibleAutomaticSources.includes(this.unchangedOpportunity.source_type)) {
        this.isCardManual = false;

        return;
      }

      this.isCardManual = true;
    },

    checkIfWillCloseTheOpportunity() {
      if (this.unchangedOpportunity.sales_stage_id == this.sales_stage_id) return false;

      const saleStage = this.saleStages.find((stage) => stage.id === this.sales_stage_id);

      if (!saleStage) return false;

      this.willWinTheOpportunity = saleStage.type === "Won";
      this.willLoseTheOpportunity = saleStage.type === "Lost";

      if (this.willWinTheOpportunity) {
        if (!this.product || !this.price) {
          this.handleProductPriceError();
        } else {
          this.$bvModal.show("bv-close_deal_won");
        }

        return true;
      }

      if (this.willLoseTheOpportunity) {
        this.$bvModal.show("bv-close_deal_lost");

        return true;
      }

      return false;
    },

    checkIfWillReopenTheOpportunity() {
      const saleStage = this.saleStages.find((stage) => stage.id === this.sales_stage_id);

      if (!saleStage) return false;

      if (
        saleStage.type !== "Won" &&
        saleStage.type !== "Lost" &&
        (this.unchangedOpportunity.state_type === "Lost" ||
          this.unchangedOpportunity.state_type === "Won")
      ) {
        if (this.checkForUnsavedData()) {
          this.updateThenReopenOpportunity(this.sales_stage_id);
        } else {
          this.reopenOpportunity();
        }

        return true;
      }

      return false;
    },

    checkForUnsavedData() {
      const opportunity = this.getClearOpportunity();

      const isEqual = (val1, val2) => {
        if (typeof val1 !== typeof val2) return false;

        if (Array.isArray(val1) && Array.isArray(val2)) {
          if (val1.length !== val2.length) return false;
          return val1.every((item, index) => isEqual(item, val2[index]));
        } else if (typeof val1 === "object" && val1 !== null && val2 !== null) {
          const keys1 = Object.keys(val1);
          return keys1.every((key) => isEqual(val1[key], val2[key]));
        }

        return val1 === val2;
      };

      for (const key in opportunity) {
        if (opportunity.hasOwnProperty(key)) {
          if (!(key in this.unchangedOpportunity)) {
            if (opportunity[key] !== undefined) {
              return true;
            }

            continue;
          }

          if (!isEqual(opportunity[key], this.unchangedOpportunity[key])) {
            return true;
          }
        }
      }

      return false;
    },

    getFormatedClosedType(closedType) {
      return (
        this.reasons_lost.find((reason) => reason.id == closedType)?.name || "Motivo não encontrado"
      );
    },

    selectUser(user) {
      this.currentUser = user;

      this.$bvModal.hide("bv-list_users");
    },

    selectProduct(product) {
      this.product = product;

      if (product.price) this.price = product.price;
    },

    unsetProduct() {
      this.product = null;
    },

    setMobileNumberInformation(update) {
      this.countryCode = update.countryCode;
      this.editedPhoneNumber = update.formatNational;

      if (
        this.editedPhoneNumber &&
        this.editedPhoneNumber.length < 10 &&
        this.countryCode === "BR"
      ) {
        this.colorOfNumberInput = "#ea5556";
        this.needDDD = true;
      } else {
        this.needDDD = false;
      }
    },

    setLocalOpportunity() {
      if (!this.unchangedOpportunity.price) this.unchangedOpportunity.price = 0;

      this.name = this.unchangedOpportunity.name;
      this.sales_stage_id = this.unchangedOpportunity.sales_stage_id;
      this.probability = this.unchangedOpportunity.probability;
      this.price = this.unchangedOpportunity.price;
      this.source_type = this.unchangedOpportunity.source_type;
      this.media_id = this.unchangedOpportunity.media_id;
      this.campaign_id = this.unchangedOpportunity.campaign_id;
      this.workspace_id = this.unchangedOpportunity.workspace_id;
      this.campaign_url = this.unchangedOpportunity.campaign_url;
      this.description = this.unchangedOpportunity.description;
      this.is_responsible = this.unchangedOpportunity.is_responsible;
      this.student_name = this.unchangedOpportunity.student_name;
      this.student_document = this.unchangedOpportunity.student_document;
      this.opportunity_type_id = this.unchangedOpportunity.opportunity_type_id;
      this.visit_generator = this.unchangedOpportunity.visit_generator;
      this.form_id = this.unchangedOpportunity.form_id;

      this.product = this.unchangedOpportunity.product
        ? { ...this.unchangedOpportunity.product }
        : null;

      this.tags = this.unchangedOpportunity.tags;

      this.contact = { ...this.unchangedOpportunity.contact };
      this.phone_number = this.unchangedOpportunity.contact.phone_number;

      this.currentUser = this.unchangedOpportunity.user;

      this.currentWorkspace = this.unchangedOpportunity.workspace;

      this.opportunity = this.unchangedOpportunity;

      this.checkIfCardIsManual();
    },

    checkForMoneyError(value) {
      if (value < 0) {
        this.price = 0;
      } else if (value > 9999999.99) {
        this.price = 9999999.99;
      }
    },

    checkDescriptionLimit() {
      if (this.description.length > 10000) {
        this.$bvToast.toast(
          "O limite de caracteres do campo de descrição é de 10.000 caracteres.",
          {
            title: `Limite da Descrição alcançado!`,
            autoHideDelay: 2500,
            variant: "warning",
            toaster: "b-toaster-top-left",
            solid: true,
          }
        );

        this.description = this.description.substring(0, 10000);
      }
    },

    getBlockedContentSourceType(blockedContent) {
      const blockedContents = {
        Bella: "Bella",
        FormularioSite: "Formulário Site",
        URA: "URA",
      };

      return blockedContents[blockedContent];
    },

    getClearOpportunity() {
      if (this.contact) {
        delete this.contact.workspace_id;
        delete this.contact.workspace;
        delete this.contact.opportunities;
        delete this.contact.created_at;
        delete this.contact.updated_at;
      }

      const opportunity = {
        id: this.unchangedOpportunity.id,
        name: this.name,
        opportunity_type_id: this.opportunity_type_id,
        source_type: this.source_type,
        media_id: this.media_id,
        workspace_id: this.workspace_id,
        is_responsible: this.is_responsible,
        student_name: this.student_name,
        student_document: this.student_document,
        description: this.description,
        campaign_id: this.campaign_id,
        campaign_url: this.campaign_url,
        probability: this.probability,
        user_id: this.currentUser.id,
        product_id: this.product?.id || null,
        price: this.price,
        contact_id: this.contact.id,

        tags: this.tags,

        contact: this.contact,
      };

      opportunity.contact.phone_number = this.$service.clearPhoneNumber(this.phone_number);
      opportunity.contact.country_code = this.countryCode;

      return opportunity;
    },

    handleKeydown(event) {
      if (event.key === "Enter") {
        event.preventDefault();

        this.updateOpportunity();
      }
    },

    handleTaskSave(task) {
      this.currentTask = task;

      this.formattedTaskTimestamp = moment(
        this.currentTask.start_timestamp,
        "YYYY-MM-DD HH:mm:ss"
      ).format("DD/MM/YYYY HH:mm");

      this.$bvModal.hide("update-opportunity-task-form");

      this.$bvToast.toast(
        `Tarefa ${this.currentTask?.is_registry ? "registrada em" : "agendada para"} ${
          this.formattedTaskTimestamp
        }`,
        {
          title: "Sucesso!",
          autoHideDelay: 4000,
          variant: "success",
          toaster: "b-toaster-top-left",
          solid: true,
        }
      );
    },

    handleUpdateTaskCount() {
      if (this.unchangedOpportunity.task?.count) {
        this.unchangedOpportunity.task.count++;
      } else {
        this.unchangedOpportunity.task = { count: 1 };
      }
    },

    handleProductPriceError() {
      this.hasProductPriceError = true;

      this.$bvToast.toast(this.requiredProductError, {
        title: "Oportunidade sem Produto e Valor!",
        autoHideDelay: 2500,
        variant: "warning",
        toaster: "b-toaster-top-left",
        solid: true,
      });

      const productItem = document.getElementById("productItem");

      if (productItem) productItem.scrollIntoView({ block: "center", behavior: "smooth" });
    },

    handleCloseDeal(state_type) {
      if (this.unchangedOpportunity.state_type == state_type) {
        if (this.checkForUnsavedData()) {
          this.updateThenReopenOpportunity();
        } else {
          this.reopenOpportunity();
        }

        return;
      }

      this.willWinTheOpportunity = state_type === "Won";
      this.willLoseTheOpportunity = state_type === "Lost";

      if (!this.unchangedOpportunity.task?.count) {
        this.$bvModal.show("update-opportunity-task-form-stage-swap");

        this.$bvToast.toast(
          "Uma oportunidade deve ter ao menos uma tarefa para poder mudar de etapa!",
          {
            title: `Oportunidade sem tarefa!`,
            autoHideDelay: 2500,
            variant: "warning",
            toaster: "b-toaster-top-left",
            solid: true,
          }
        );

        return;
      }

      let newSaleStageIndex;

      if (this.willWinTheOpportunity)
        newSaleStageIndex = this.saleStages.findIndex((stage) => stage.type === "Won");

      if (this.willLoseTheOpportunity)
        newSaleStageIndex = this.saleStages.findIndex((stage) => stage.type === "Lost");

      if (!newSaleStageIndex) {
        this.$bvToast.toast(
          "Não foi possível encontrar o estágio de conclusão no workspace atual",
          {
            title: "Etapa de conclusão não encontrada",
            variant: "danger",
            solid: true,
          }
        );

        return;
      }

      this.sales_stage_id = this.saleStages[newSaleStageIndex].id;

      this.updateOpportunity();
    },

    handleSaveDeal(event) {
      if (!event?.state) return;

      if (event.state == "Won") this.trigger_room_status = event.opportunity?.trigger_room_status;

      if (event.state == "Lost") {
        this.closed_type = event.opportunity?.closed_type;
        this.closed_description = event.opportunity?.closed_description;
      }

      this.$bvModal.hide(`bv-close_deal_${event.state.toLowerCase()}`);

      if (this.checkForUnsavedData()) {
        this.updateThenClose();

        return;
      }

      this.closeOpportunity();
    },

    handleFormError() {
      this.$bvToast.toast("Por favor, preencha todos os campos obrigatórios", {
        title: "Campos obrigatórios",
        variant: "warning",
        solid: true,
      });

      const errorField = document.querySelector(".v-err");

      if (errorField) errorField.scrollIntoView({ block: "center", behavior: "smooth" });
    },

    handleVerifyCancel() {
      if (
        this.checkForUnsavedData() ||
        this.unchangedOpportunity.sales_stage_id != this.sales_stage_id
      ) {
        this.$bvModal.show("verify-cancel-opportunity");
      } else {
        this.$emit("close");
      }
    },

    redirectToContacts() {
      if (this.$route.path == "/contacts") return;

      this.$store.commit(
        "contactStore/setContactIdFromOpportunityRedirect",
        this.unchangedOpportunity.contact.id
      );

      this.$router.push(`${"/contacts"}`);
    },

    redirectToInternalAdmin() {
      this.$router.push("/lists");
    },

    fetchLostReasons() {
      if (this.reasons_lost.length) return;

      this.$store.dispatch("opportunityStore/fetchLostReasons");
    },

    fetchCampaigns() {
      if (this.campaigns.length) return;

      this.$store.dispatch("opportunityStore/fetchCampaigns");
    },

    fetchMedias() {
      if (this.medias.length) return;

      this.$store.dispatch("opportunityStore/fetchMedias");
    },

    fetchSalesStages() {
      this.hasSalesStageRequest = true;

      this.$store
        .dispatch("admin/fetchSalesStages")
        .then((response) => {
          this.saleStages = response.data;
        })
        .finally(() => {
          this.hasSalesStageRequest = false;
        });
    },

    fetchOpportunity() {
      this.hasOpportunityRequest = true;

      if (this.opportunity_prop) {
        this.unchangedOpportunity = this.opportunity_prop;

        this.setLocalOpportunity(this.opportunity_prop);

        this.hasOpportunityRequest = false;

        return;
      }

      this.$store
        .dispatch("opportunityStore/fetchOne", this.id)
        .then((response) => {
          this.unchangedOpportunity = response.data;

          this.setLocalOpportunity(response.data);
        })
        .finally(() => {
          this.hasOpportunityRequest = false;
        });
    },

    singleVoipCall() {
      if (this.hasVoipCredentialsRequest) return;

      this.hasVoipCredentialsRequest = true;

      this.$store
        .dispatch("externalPhone/getSipCredentials", this.user.id)
        .then((response) => {
          if (response.data.voip_username && response.data.voip_password) {
            this.$store.commit("externalPhone/CHANGE_USER_CREDENTIALS", {
              user_name: response.data.voip_username,
              password: response.data.voip_password,
            });

            this.$store.commit(
              "externalPhone/CHANGE_SINGLE_OPPORTUNITY_ID",
              this.unchangedOpportunity.id
            );

            this.$store.commit("externalPhone/CHANGE_IS_SINGULAR_CALL", true);
            this.$store.commit("externalPhone/CHANGE_IS_DIALING", true);
            this.$store.commit("externalPhone/CHANGE_STATUS", "onHold");
            this.$store.commit("externalPhone/TRIGGER_START_CALLS");
          } else {
            this.$bvToast.toast("Usuário sem credenciais de VoIP neste workspace", {
              title: "Usuário sem acesso",
              variant: "warning",
              solid: true,
            });
          }
        })
        .finally(() => {
          this.hasVoipCredentialsRequest = false;
        });
    },

    updateOpportunity() {
      if (this.hasSaveRequest) return;

      const formRefs = this.$refs.formValidation;

      formRefs.validate().then((success) => {
        if (success) {
          if (this.checkIfWillCloseTheOpportunity()) return;

          if (this.checkIfWillReopenTheOpportunity()) return;

          this.hasSaveRequest = true;

          const opportunity = this.getClearOpportunity();

          this.$store
            .dispatch("opportunityStore/save", opportunity)
            .then((response) => {
              if (response.data?.sales_stage_id != this.sales_stage_id) {
                this.moveOpportunity();

                return;
              }

              this.$emit("saved");
            })
            .finally(() => {
              this.hasSaveRequest = false;
            });

          return;
        }

        this.handleFormError();
      });
    },

    updateThenClose() {
      const formRefs = this.$refs.formValidation;

      formRefs.validate().then((success) => {
        if (success) {
          this.hasSaveRequest = true;

          const opportunity = this.getClearOpportunity();

          this.$store
            .dispatch("opportunityStore/save", opportunity)
            .then(() => {
              this.closeOpportunity();
            })
            .finally(() => {
              this.hasSaveRequest = false;
            });

          return;
        }

        this.handleFormError();
      });
    },

    updateThenReopenOpportunity(sales_stage_id = null) {
      const formRefs = this.$refs.formValidation;

      formRefs.validate().then((success) => {
        if (success) {
          this.hasSaveRequest = true;

          const opportunity = this.getClearOpportunity();

          this.$store
            .dispatch("opportunityStore/save", opportunity)
            .then(() => {
              this.reopenOpportunity(sales_stage_id);
            })
            .finally(() => {
              this.hasSaveRequest = false;
            });

          return;
        }

        this.handleFormError();
      });
    },

    closeOpportunity() {
      this.hasSaveRequest = true;

      const closingData = {
        id: this.unchangedOpportunity.id,
        workspace_id: this.unchangedOpportunity.workspace_id,

        data: {
          state_type: this.willWinTheOpportunity ? "Won" : "Lost",
          closed_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
      };

      if (this.willWinTheOpportunity)
        closingData.data.trigger_room_status = this.trigger_room_status;

      if (this.willLoseTheOpportunity) {
        closingData.data.closed_type = this.closed_type;
        closingData.data.closed_description = this.closed_description;
      }

      this.$store
        .dispatch("opportunityStore/closeOpportunity", closingData)
        .then((response) => {
          if (
            this.willWinTheOpportunity &&
            this.platform?.integrated_enroll == "imNic" &&
            localStorage.getItem("imNicToken")
          ) {
            this.$store.dispatch("contract/setOpportunityForContract", response.data);

            this.$bvModal.show("bv-enrollment_form");
          }

          this.$emit("saved");
        })
        .finally(() => {
          this.hasSaveRequest = false;
        });
    },

    reopenOpportunity(sales_stage_id = null) {
      const reOpeningData = {
        id: this.unchangedOpportunity.id,
        workspace_id: this.unchangedOpportunity.workspace_id,

        data: {
          state_type: "Open",
          closed_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          closed_type: null,
          closed_description: null,
        },
      };

      const newSaleStage = this.$service.findLast(
        this.saleStages,
        (stage) => stage.type !== "Won" && stage.type !== "Lost"
      );

      if (!newSaleStage) {
        this.$bvToast.toast(
          "Não foi possível encontrar um estágio de abertura no workspace atual",
          {
            title: "Etapa de abertura não encontrada!",
            variant: "danger",
            solid: true,
          }
        );

        return;
      }

      const moveData = {
        id: this.unchangedOpportunity.id,
        workspace_id: this.unchangedOpportunity.workspace_id,

        data: {
          sales_stage_id: sales_stage_id || newSaleStage.id,
          display_order: "top",
        },
      };

      this.$store
        .dispatch("opportunityStore/closeOpportunity", reOpeningData)
        .then(() => {
          this.$store
            .dispatch("opportunityStore/move", moveData)
            .then(() => {
              this.$emit("saved");
            })
            .finally(() => {
              this.hasSaveRequest = false;
            });
        })
        .finally(() => {
          this.hasSaveRequest = false;
        });
    },

    moveOpportunity() {
      this.hasSaveRequest = true;

      const moveData = {
        id: this.unchangedOpportunity.id,
        workspace_id: this.unchangedOpportunity.workspace_id,

        data: {
          sales_stage_id: this.sales_stage_id,
          display_order: "top",
        },
      };

      this.$store
        .dispatch("opportunityStore/move", moveData)
        .then(() => {
          this.$emit("saved");
        })
        .finally(() => {
          this.hasSaveRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.update-opportunity {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .form-holder {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0.5rem 1.5rem 1rem 1.5rem;
    gap: 15px;
    overflow-x: hidden;
    overflow-y: auto;

    legend {
      font-size: 1em;
      padding-top: 0.5em;
      margin-bottom: -5px;
      color: #000000;
    }

    .low-gap-sub-group {
      display: flex;
      flex-direction: column;
      gap: 7px;

      .enrollment-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        width: 100%;
        padding: 0.7rem 0;
        gap: 10px;
        border-radius: 5px;
        border: solid 1px #046af3;
        cursor: pointer;

        .enroll-icon {
          font-size: 30px;
          color: #046af3;
        }
      }

      .lost-reason-description {
        display: flex;
        flex-direction: column;
        padding: 5px 10px;
        gap: 5px;
        border-radius: 4px;
        border: 1px solid #ff2c55;
        background: #ffbbc7;

        .lost-title {
          text-align: start;
          font-size: 16px;
          color: #000;
        }

        .lost-description {
          text-align: start;
          color: #767383;
        }
      }

      .add-task-button {
        width: 100%;
        padding: 15px;
        transition: 0.4s background ease-in-out;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2) !important;
        border: none;

        &:hover {
          background: #4594ff !important;
          box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.25) !important;
        }
      }

      .task-collapse {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 175px;
        padding: 1rem;
        background: #ffffff;
        border: solid 1px #93abbe;
        border-radius: 10px;
        cursor: auto;

        .task-collapse-title {
          text-align: center;
          font-size: 15px;
          font-weight: 500;
          color: #000000;
        }

        .task-buttons {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 1rem;
          border-radius: 5px;
          cursor: pointer;
        }

        .task-register {
          margin-top: 15px;
          border: solid 1px;
          border-left: solid 5px;
          border-color: #2ed47a !important;
        }

        .task-schedule {
          margin-top: 10px;
          border: solid 1px;
          border-left: solid 5px;
          border-color: #2196f3 !important;
        }
      }
    }

    .call-component-holder {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 1.5rem 10px 1.5rem;
      gap: 10px;

      .call-ongoing {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        height: 46px;
        width: 57%;
        gap: 10px;
        border-radius: 10px;
        border: solid 1px #2ed47a80;
        background: #f5fdf8;
        color: #000000;
      }

      .white-green-circle {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        border-radius: 100%;
        border: solid 4px #2ed47a;
        background: #ffffff;
      }

      .dial-stop {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        height: 46px;
        width: 40%;
        gap: 5px;
        border-radius: 10px;
        border: none;
        background: #ff0025 !important;
        color: #ffffff;
      }

      .warning-text {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.01em;
        text-align: left;
        color: #4c5862;
      }
    }

    .b-tab-style {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;

      .input-field-container {
        margin: 0 !important;
      }

      .form-boxes {
        padding: 5px;
        border-radius: 5px;
        margin: 0;
        cursor: pointer;
      }

      .price-field {
        margin: 0 !important;

        .floating {
          float: left;
          font-size: 15px;
          margin-top: -30px;
          margin-left: 16px;
          color: #535e68;
        }

        .price {
          text-align: right;
          background: transparent;
          color: black;
          font-weight: 600;
        }
      }

      .is-responsible {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .responsibility-checkbox-holder {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          .custom-checkbox {
            height: 18px;
            width: 18px;
            border-radius: 2px;
          }
        }
      }

      .blocked-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px;
        background: #e5e5e5;
        border: solid 1px #d2d2d2;
      }

      .edit-lists-redirect {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px;
        gap: 5px;
        border-radius: 8px;
        border: solid 1px #046af3;
        color: #046af3;
        cursor: pointer;
      }
    }
  }

  .form-holder > * {
    flex-shrink: 0;
  }

  .error-border {
    border-color: #ea5455 !important;
  }

  fieldset.error-border input.price {
    border-color: #ea5455 !important;
  }

  .spinner-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .bottom-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    margin-top: auto;
    border-top: 1px solid #ececec;
    color: #000000;
    background: #ffffff;
  }
}
</style>
