<template>
  <div ref="content" class="position-relative">
    <slot name="content" />

    <div
      v-if="hasRequest && !deleteSpinner"
      class="d-flex align-items-center justify-content-center mt-1"
      v-bind:class="{ horizontal: isHorizontal }"
    >
      <b-spinner />
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  name: "InfiteScroll",

  components: {
    BSpinner,
  },

  mounted() {
    let elm = null;
    if (this.scrollRef) {
      elm = this.$refs.content.querySelector(".kanban-drag");
    } else {
      elm = this.$refs.content;
    }

    elm.addEventListener("scroll", () => {
      let boundariesReached = Math.ceil(elm.scrollTop + elm.clientHeight + 1) >= elm.scrollHeight;

      if (this.isHorizontal) {
        boundariesReached = Math.ceil(elm.scrollLeft + elm.clientWidth + 1) >= elm.scrollWidth;
      }

      if (boundariesReached && !this.hasRequest) {
        this.$emit("fetch");
      }
    });
  },

  props: {
    hasRequest: {
      type: Boolean,
      default: false,
    },

    isHorizontal: {
      type: Boolean,
      default: false,
    },

    scrollRef: {
      type: String,
      default: null,
    },

    deleteSpinner: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>
<style scoped>
.horizontal {
  position: absolute;
  right: 0%;
  top: 20%;
}
.bd {
  outline: 2px solid darkmagenta;
}
</style>
