<template>
  <div>
    <b-modal
      id="bv-create_opportunity_modal_inside_task"
      class="modal-dialog"
      hide-footer
      hide-header
    >
      <create-opportunity
        @saved="refreshOpportunityListEvent()"
        @close="hideModal('bv-create_opportunity_modal_inside_task')"
      />
    </b-modal>

    <b-modal id="bv-edit_task_opportunity" class="modal-dialog" hide-footer hide-header>
      <update-opportunity
        v-if="opportunityIdFromTask"
        @close="hideModal('bv-edit_task_opportunity')"
        @saved="refreshCurrentTask()"
        :id="opportunityIdFromTask"
      />
    </b-modal>
  </div>
</template>

<script>
import CreateOpportunity from "@/views/forms/CreateOpportunity.vue";
import UpdateOpportunity from "@/views/forms/UpdateOpportunity.vue";

export default {
  components: {
    CreateOpportunity,
    UpdateOpportunity,
  },

  computed: {
    opportunityIdFromTask() {
      return this.$store.getters["bmodalEvents/getCurrentOpportunityId"];
    },
  },

  methods: {
    hideModal(modal) {
      this.$bvModal.hide(modal);
    },

    refreshOpportunityListEvent() {
      this.$store.dispatch("bmodalEvents/disptachOpportunityCreationInsideOpportunitySelectEvent");
    },

    refreshCurrentTask() {
      this.$store.dispatch("bmodalEvents/dispatchOpportunityCreationInsideTaskEvent");
    },
  },
};
</script>

<style lang="scss" scoped></style>
